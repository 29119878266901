export class Point {
	public x: number;
	public y: number;

	constructor(x: number = 0, y: number = 0) {
		this.x = x;
		this.y = y;
	}

	max(point: Point): Point {
		this.x = Math.max(this.x, point.x);
		this.y = Math.max(this.y, point.y);
		return this;
	}

	min(point: Point): Point {
		this.x = Math.min(this.x, point.x);
		this.y = Math.min(this.y, point.y);
		return this;
	}

	add(point: Point): Point {
		this.x = (this.x + point.x);
		this.y = (this.y + point.y);
		return this;
	}

	sub(point: Point): Point {
		this.x = (this.x - point.x);
		this.y = (this.y - point.y);
		return this;
	}

	clone(): Point {
		return new Point(this.x, this.y);
	}
}
