import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CommonHeadersInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const cloneReq: HttpRequest<any> = req.clone({
			headers: req.headers.set('X-Request-Application', 'Front'),  // used inside WF scripts CASEM-64104
		});
		return next.handle(cloneReq);
	}
}
