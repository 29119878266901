// New version src/Common/pipes/common-switch-sign-of-number-formatter.pipe.ts
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/common_switch_sign_of_number_filter.js

import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash';

@Pipe({
	name: 'commonSwitchSignOfNumberFormatter',
})
export class CommonSwitchSignOfNumberFormatterPipe implements PipeTransform {
	transform(num: number) {
		return isNumber(num) && num !== 0 ? num * -1 : num;
	}
}
