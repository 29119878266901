// New version src/Common/pipes/common-pretty-number.pipe.ts
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/pretty_number_filter.js
import { Pipe, PipeTransform } from '@angular/core';
import { CommonGeneralSettingsService } from '../general_settings/general_settings.service';
import { COMMON_DEFAULT_NUMBER_CONSTANTS } from '../constants/common-default-number.constants';
import { isNumber } from 'lodash';


export interface ICommonPrettyNumberOptions {
	digitsAfterDecimal?: number,
	fixAfterDecimal?: boolean,  // cut off to true by default is necessary numbers of signs after a comma
	includeTrailingZeros?: boolean,
}


@Pipe({
	name: 'commonPrettyNumber',
})
export class CommonPrettyNumberPipe implements PipeTransform {
	constructor (
		private commonGeneralSettingsService: CommonGeneralSettingsService,
	) {}

	transform(
		num: number | string,
		options?: ICommonPrettyNumberOptions,
	): string {
		if (!num) {
			return num.toString();
		}

		options = options || {};
		options.fixAfterDecimal = options.fixAfterDecimal !== false;
		options.digitsAfterDecimal = isNumber(options.digitsAfterDecimal) ? options.digitsAfterDecimal : COMMON_DEFAULT_NUMBER_CONSTANTS.DIGITS_AFTER_DECIMAL;

		const decimalSeparator = this.commonGeneralSettingsService.getGeneralSettings('NumberFormat.DecimalSeparator') || COMMON_DEFAULT_NUMBER_CONSTANTS.DECIMAL_SEPARATOR;
		const groupSeparator = this.commonGeneralSettingsService.getGeneralSettings('NumberFormat.GroupSeparator') || COMMON_DEFAULT_NUMBER_CONSTANTS.GROUP_SEPARATOR;

		if (options.fixAfterDecimal && isNumber(num)) {
			const multiplex = Math.pow(10, options.digitsAfterDecimal);
			num = Math.round(multiplex * num) / multiplex;
		}

		let result = num.toString()
			.replace(/[' ']/g, '')
			.replace('.', decimalSeparator);

		const results = result.split(decimalSeparator);
		const main = results[0];
		let tail = results[1] || '';

		const replacedMain = main.replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator);

		if (options.includeTrailingZeros) {
			while (tail.length < options.digitsAfterDecimal) {
				tail += '0';
			}
		}

		result = replacedMain + (tail ? decimalSeparator + tail : '');

		if (options.fixAfterDecimal) {
			const decimalIndex = result.indexOf(decimalSeparator);

			if (decimalIndex !== -1) {
				result = result.slice(0, decimalIndex + options.digitsAfterDecimal + 1);
			}
		}

		return result;
	}
}
