import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CommonAppDataService } from '../app_data/app_data.service';

@Injectable()
export class CommonRelativeRequestsInterceptor implements HttpInterceptor {
	private readonly appData = this.commonAppDataService.getData();

	constructor(
		private commonAppDataService: CommonAppDataService,
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.isAbsoluteUrl(req.url)) {
			return next.handle(req);
		} else {
			const cloneReq = req.clone({
				url: `${this.appData.appFullLocationUrl}${req.url}`,  // fix the wrong url with base href (auth app, for example)
			});

			return next.handle(cloneReq);
		}
	}

	private isAbsoluteUrl(url) {
		return (url.indexOf('://') > 0 || url.indexOf('//') === 0);  // more efficient than regular expressions
	}
}
