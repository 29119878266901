import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';

import { CommonPopupContainerComponent } from './components/popup-container/popup-container.component';
import { CommonPopupService } from './services/popup.service';
import { CommonPopupHeaderWrapperComponent } from './components/popup-header-wrapper/popup-header-wrapper.component';
import { CommonPopupOverlayBackgroundComponent } from './components/popup-overlay-background/popup-overlay-background.component';
import { CommonPopupLoaderComponent } from './components/popup-loader/popup-loader.component';
import { CommonLoaderModule } from '../loader/loader.module';
import { CommonActionsDropdownModule } from '../context-menu/actions-dropdown.component/common-actions-dropdown.module';
import { CommonLocaleModule } from '../locale/locale.module';

@NgModule({
	imports: [
		CommonModule,
		OverlayModule,
		PortalModule,
		CommonLocaleModule,
		CommonLoaderModule,
		CommonActionsDropdownModule,
	],
	exports: [
		OverlayModule,
		PortalModule,
		CommonLocaleModule,
		CommonPopupContainerComponent,
		CommonLoaderModule,
	],
	providers: [
		CommonPopupService,
	],
	declarations: [
		CommonPopupContainerComponent,
		CommonPopupHeaderWrapperComponent,
		CommonPopupOverlayBackgroundComponent,
		CommonPopupLoaderComponent,
	],
	entryComponents: [
		CommonPopupContainerComponent,
		CommonPopupOverlayBackgroundComponent,
		CommonPopupLoaderComponent,
	],
})
export class CommonPopupModule {}
