import { Injectable, Injector } from '@angular/core';
import { first } from 'rxjs/operators';

import { COMMON_CONTEXT_MENU_EVENT_NAME, ICommonContextMenuOptions } from './common-context-menu.interfaces';
import { CommonContextMenu } from './common-context-menu.service';

@Injectable({
	providedIn: 'root',
})
export class CommonContextMenuFactory {
	public get isOpenContextMenu(): boolean {
		return !!this.openedContextMenus.size;
	}

	private openedContextMenus: Set<CommonContextMenu<any>> = new Set();

	constructor(
		private injector: Injector,
	) {
	}

	open<DataType = any> (options: ICommonContextMenuOptions<DataType>): CommonContextMenu<DataType> {
		if (options.closeOtherContextMenu !== false) {
			this.closeOtherContextMenu();
		}

		if (options.id) {
			this.closeContextMenuById(options.id);
		}

		const contextMenu = new CommonContextMenu<DataType>(
			options,
			this.injector,
		);

		contextMenu.onEvent(COMMON_CONTEXT_MENU_EVENT_NAME.AFTER_CLOSE)
			.pipe(first())
			.subscribe(() => {
				this.openedContextMenus.delete(contextMenu);
			});
		this.openedContextMenus.add(contextMenu);

		return contextMenu;
	}

	closeOtherContextMenu() {
		Array.from(this.openedContextMenus).forEach((contextMenu) => contextMenu.close());
	}

	closeContextMenuById(id: string) {
		Array.from(this.openedContextMenus).forEach((contextMenu) => {
			if (contextMenu.id === id) {
				contextMenu.close();
			}
		});
	}
}
