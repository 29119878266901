import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

const urls = {
	login: 'authentication/account/login',
	changePhone: 'authentication/account/changePhone',
	confirmPhoneCode: 'authentication/account/checkPhoneConfirmationCode',
	checkLoginCode: 'authentication/account/checkValidationCode',
	resendPhoneCode: 'authentication/account/resendPhoneConfirmationCode',
	resendLoginCode: 'authentication/account/resendCode',
	remindPassword: 'authentication/account/remindPassword',
	resetPassword: 'api/accountactions/resetpassword',
	register: 'api/users/registerByInvite',
};

@Injectable()
export class AuthenticationUserService {
	constructor(
		private http: HttpClient,
	) {}

	login (data): Observable<{}> {
		return this.http.post(urls.login, data);
	}

	changePhone (data): Observable<{}> {
		return this.http.post(urls.changePhone, data);
	}

	confirmPhoneCode (data): Observable<{}> {
		return this.http.post(urls.confirmPhoneCode, data);
	}

	checkLoginCode (data): Observable<{}> {
		return this.http.post(urls.checkLoginCode, data);
	}

	resendPhoneCode (data): Observable<{}> {
		return this.http.post(urls.resendPhoneCode, data);
	}

	resendLoginCode (data): Observable<{}> {
		return this.http.post(urls.resendLoginCode, data);
	}

	remindPassword (data): Observable<{}> {
		return this.http.post(urls.remindPassword, data);
	}

	resetPassword (data): Observable<{}> {
		return this.http.post(urls.resetPassword, data);
	}

	register (data): Observable<{}> {
		return this.http.post(urls.register, data);
	}
}
