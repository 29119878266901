const div = document.createElement('div');

export function stringToHtmlElements (html: string): HTMLElement {
	div.innerHTML = html;

	return div.firstChild as HTMLElement;
}

// https://s3.memeshappen.com/memes/im-not-saying-its-magic-but-its-magic.jpg
// Replace html symbols (&#01) in string to real symbols
export function stringToHtmlString (html: string): string {
	div.innerHTML = html;
	return div.innerHTML;
}
