import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {CommonValidator} from '../common-validator';
import {isEmpty} from 'lodash';

export const COMMON_MATCH_ERROR_KEY = 'commonMatch';

export interface ICommonMatchValidatorContext {
	matchControl: AbstractControl,
}

@Injectable({
	providedIn: 'root',
})
export class CommonMatchValidator extends CommonValidator {
	getValidator(context: ICommonMatchValidatorContext) {
		return (control: AbstractControl): ValidationErrors | null => {
			const changeSubscription = context.matchControl.valueChanges.subscribe(() => {
				control.updateValueAndValidity();
				changeSubscription.unsubscribe();
			});

			if (isEmpty(control.value) || context.matchControl.value === control.value) {
				return null;
			} else {
				return {
					[COMMON_MATCH_ERROR_KEY]: true,
				};
			}
		};
	}
}
