import { NgModule } from '@angular/core';
import { CommonGeneralSettingsDaysOfWeekDictionaryService } from './services/general-settings-days-of-week-dictionary.service';
import { CommonGeneralSettingsDaysOfWeekResourceService } from './services/general-settings-days-of-week-resource.service';

@NgModule({
	providers: [
		CommonGeneralSettingsDaysOfWeekDictionaryService,
		CommonGeneralSettingsDaysOfWeekResourceService,
	],
})
export class CommonGeneralSettingsDaysOfWeekModule {}
