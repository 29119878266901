// Old version ContextMenuFactory src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/containers/context_menu_factory.js
// New version CommonContextMenu src/Common/context-menu/context-menu.service/common-context-menu.service.ts

import {
	ApplicationRef,
	ComponentFactoryResolver,
	ComponentRef,
	ElementRef,
	InjectionToken,
	Injector,
	NgZone,
	Renderer2,
	RendererFactory2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ComponentPortal, DomPortalOutlet, PortalInjector } from '@angular/cdk/portal';
import {
	animationFrameScheduler,
	asyncScheduler,
	fromEvent,
	Observable,
	of,
	Subject,
	Subscription,
	merge,
	BehaviorSubject,
} from 'rxjs';
import { debounceTime, delay, observeOn } from 'rxjs/operators';
import { clone, forIn } from 'lodash';

import { commonAnimateStyles } from '../../utilities/style-utils/common-animate-styles';
import { ANIMATE_DURATION } from '../../constants/common_animate_duration.constant';
import { ICommonAction, TCallbackFunc } from '../../interfaces/core';
import {
	COMMON_CONTEXT_MENU_ANIMATION_DIRECTIONS,
	COMMON_CONTEXT_MENU_ATTACHMENTS,
	COMMON_CONTEXT_MENU_EVENT_NAME,
	COMMON_CONTEXT_MENU_VERTICAL_ATTACHMENTS,
	ICommonContextMenuAction,
	ICommonContextMenuOptions,
	ICommonContextMenuOptionsContainerForPosition,
	ICommonContextMenuOptionsScreen,
	ICommonContextMenuScreen,
	TCommonContextMenuElementOrSelector,
	TCommonContextMenuScreenName,
} from './common-context-menu.interfaces';
import { CommonContextMenuBaseScreenComponent } from './components/common-context-menu-base-screen.component';
import { Point } from '../../utilities/point';
import { Rect } from '../../utilities/rect';
import { CommonContentWrapperService } from '../../content-wrapper/common-content-wrapper.service';
import { COMMON_THROTTLE_MILLISECONDS } from '../../controls/control/throttler_milliseconds.constant';
import { COMMON_CONTENT_WRAPPER_EVENT } from '../../content-wrapper/common-content-wrapper.interfaces';

export const COMMON_CONTEXT_MENU_DATA = new InjectionToken<any>('COMMON_CONTEXT_MENU_DATA');
export const COMMON_CONTEXT_MENU_ENTITY = new InjectionToken<CommonContextMenu<any>>('COMMON_CONTEXT_MENU_ENTITY');
export const COMMON_CONTEXT_MENU_SCROLL_ELEMENT_CLASS = 'b-common_context_menu-scroll';
export const COMMON_CONTEXT_MENU_NO_SCROLL_ELEMENT_CLASS = 'j-context_menu-no_scroll';
export const CONTEXT_MENU_SCREEN_ELEMENT_CLASS = 'b-common_context_menu-screen';
export const COMMON_CONTEXT_MENU_OPENED_FLAG = new BehaviorSubject<boolean>(false);

const MAX_CONTEXT_MENU_HEIGHT = 768;
const MIN_RECOMMENDED_CONTEXT_MENU_HEIGHT = 112;
const TARGET_EL_OPENED_CONTEXT_MENU_CLASS = 'context_menu_opened';
const CONTEXT_MENU_IN_ANIMATE_CLASS = 'b-common_context_menu-animate';

const ANIMATION_DIRECTIONS = {
	[COMMON_CONTEXT_MENU_ANIMATION_DIRECTIONS.LEFT_TO_RIGHT]: {
		oldElement: {
			end: {
				left: 100,
			},
		},
		newElement: {
			start: {
				left: -100,
			},
		},
	},
	[COMMON_CONTEXT_MENU_ANIMATION_DIRECTIONS.RIGHT_TO_LEFT]: {
		oldElement: {
			end: {
				left: -100,
			},
		},
		newElement: {
			start: {
				left: 100,
			},
		},
	},
};

export class CommonContextMenu<DataType> {
	public activeScreenName: TCommonContextMenuScreenName = 'default';
	public get activeScreenOptions(): ICommonContextMenuOptionsScreen {
		return this.options && this.options.screens[this.activeScreenName] || null;
	}
	public activeScreenElem: HTMLElement = null;
	public activeScreenComponentPortal: ComponentPortal<CommonContextMenuBaseScreenComponent> = null;
	public activeScreenComponentRef: ComponentRef<CommonContextMenuBaseScreenComponent> = null;
	public id: string;
	public isOpen: boolean = false;
	public isInAnimate: boolean = false;

	private options: ICommonContextMenuOptions<DataType> = {
		dismissClickIgnore: [],
		containersForPosition: [],
		cssClasses: [],
		cssAdditionalClasses: [],
		withOpenAnimate: false,
		withChangeScreenAnimate: false,
		attachment: COMMON_CONTEXT_MENU_ATTACHMENTS.LEFT,
		verticalAttachment: COMMON_CONTEXT_MENU_VERTICAL_ATTACHMENTS.BOTTOM,
		containerXOffset: 0,
		containerYOffset: 64,
		targetXOffset: 0,
		targetYOffset: 0,
	} as ICommonContextMenuOptions<DataType>;
	private readonly preparedOptionsDismissClickIgnore: HTMLElement[] = [];
	private readonly preparedOptionsContainersForPosition: Array<ICommonContextMenuOptionsContainerForPosition & { element: HTMLElement }> = [];
	private readonly contextMenuContainerEl: HTMLDivElement = null;
	private readonly contextMenuEl: HTMLDivElement = null;
	private readonly contextMenuBodyEl: HTMLDivElement = null;
	private hostPortal: DomPortalOutlet = null;
	private readonly renderer: Renderer2 = null;
	private readonly bodyElement: HTMLBodyElement;
	private readonly htmlElement: HTMLElement;
	private domEventListeners: TCallbackFunc[] = [];
	private subscriptions: Subscription[] = [];
	private openUpDirection = false;
	private maxHeight: number;
	private eventSubjects = new Map<COMMON_CONTEXT_MENU_EVENT_NAME, Subject<ICommonContextMenuAction>>();

	private defAnimates: {
		[name: string]: (...args) => Promise<any>,
	} = Object.entries(ANIMATION_DIRECTIONS).reduce((defAnimates, [name, directionRules]) => {
		defAnimates[name] = async (
			newScreenElm: HTMLElement,
			oldScreenElm: HTMLElement,
			heightControlling?: boolean,
		) => {
			let height: number = newScreenElm.offsetHeight;
			let newWidth: number;
			let oldWidth: number;
			{
				// we need to hide old element to calculate new element's width correctly
				this.renderer.setStyle(oldScreenElm, 'display', 'none');
				this.renderer.setStyle(this.renderer.parentNode(newScreenElm), 'width', 'auto');
				newWidth = newScreenElm.offsetWidth;
				this.renderer.setStyle(oldScreenElm, 'display', 'block');
			}
			let widthDifference: number;
			{
				// we need to hide new element to calculate width difference between new and old elements correctly
				this.renderer.setStyle(newScreenElm, 'display', 'none');
				this.renderer.setStyle(this.renderer.parentNode(oldScreenElm), 'width', 'auto');
				oldWidth = oldScreenElm.offsetWidth;
				widthDifference = newWidth - oldScreenElm.offsetWidth;
				this.renderer.setStyle(newScreenElm, 'display', 'block');
			}
			const currentLeft = parseInt(window.getComputedStyle(this.contextMenuContainerEl).left, 10);
			let left: number;
			{
				switch (this.options.attachment) {
					case COMMON_CONTEXT_MENU_ATTACHMENTS.CENTER:
						left = currentLeft - widthDifference / 2;
						break;
					case COMMON_CONTEXT_MENU_ATTACHMENTS.RIGHT:
						left = currentLeft - widthDifference;
						break;
					case COMMON_CONTEXT_MENU_ATTACHMENTS.LEFT:
					default:
						left = currentLeft;
				}
			}

			if (heightControlling) {
				height = Math.min(height, this.maxHeight);
			}

			let noScrollHeight: number = 0;
			this.renderer.addClass(this.contextMenuEl, CONTEXT_MENU_IN_ANIMATE_CLASS);

			const setHeightScrollElem =  (contextHeight: number) => {
				this.renderer.setStyle(
					this.contextMenuBodyEl.querySelector(`.${COMMON_CONTEXT_MENU_SCROLL_ELEMENT_CLASS}`),
					'height',
					(contextHeight - noScrollHeight) + 'px',
				);
			};

			this.renderer.addClass(newScreenElm, CONTEXT_MENU_SCREEN_ELEMENT_CLASS);

			if (heightControlling) {
				noScrollHeight = this.calcNoScrollHeight();
			}

			this.renderer.appendChild(this.contextMenuBodyEl, newScreenElm);

			if (heightControlling && this.openUpDirection) {
				setHeightScrollElem(height);
			}

			this.renderer.setStyle(oldScreenElm, 'position', 'absolute');
			this.renderer.setStyle(newScreenElm, 'position', 'absolute');

			await Promise.all([
				commonAnimateStyles({
					duration: ANIMATE_DURATION,
					element: this.contextMenuBodyEl,
					styles: {
						height: [this.contextMenuBodyEl.offsetHeight, height, 'px'],
						width: [oldWidth, newWidth, 'px'],
					},
				}),
				commonAnimateStyles({
					duration: ANIMATE_DURATION,
					element: newScreenElm,
					styles: {
						left: [directionRules.newElement.start.left, 0, '%'],
						opacity: [0, 1],
					},
				}),
				commonAnimateStyles({
					duration: ANIMATE_DURATION,
					element: oldScreenElm,
					styles: {
						left: [0, directionRules.oldElement.end.left, '%'],
						opacity: [1, 0],
					},
				}),
				commonAnimateStyles({
					duration: ANIMATE_DURATION,
					element: this.contextMenuContainerEl,
					styles: {
						left: [currentLeft, left, 'px'],
					},
				}),
			]);

			this.renderer.removeChild(
				this.renderer.parentNode(oldScreenElm),
				oldScreenElm,
			);
			this.renderer.setStyle(newScreenElm, 'position', 'relative');
			this.renderer.setStyle(this.contextMenuBodyEl, 'height', height + 'px');

			if (heightControlling && !this.openUpDirection) {
				setHeightScrollElem(height);
			}
			this.renderer.removeClass(this.contextMenuEl, CONTEXT_MENU_IN_ANIMATE_CLASS);
		};

		return defAnimates;
	}, {});

	constructor(
		options: ICommonContextMenuOptions<DataType>,
		private injector: Injector,
	) {
		Object.assign(this.options, options);

		const rendererFactory = this.injector.get(RendererFactory2);

		this.id = this.options.id;
		this.renderer = rendererFactory.createRenderer(null, null);
		this.bodyElement = this.renderer.selectRootElement('body', true);
		this.htmlElement = this.renderer.selectRootElement('html', true);

		// some options defaults
		if (typeof this.options.scrollContainer === 'string') {
			this.options.scrollContainer = this.bodyElement.querySelector(this.options.scrollContainer) as HTMLElement;
		}
		if (Array.isArray(this.options.dismissClickIgnore)) {
			this.preparedOptionsDismissClickIgnore = this.options.dismissClickIgnore
				.filter((element) => !!element)
				.map((element) => this.convertToHTMLElement(element));
		}
		if (Array.isArray(this.options.containersForPosition)) {
			this.preparedOptionsContainersForPosition = this.options.containersForPosition
				.map((containersForPosition) => ({
					...containersForPosition,
					element: this.convertToHTMLElement(containersForPosition.element),
				}));
		}

		// generate context menu DOM container and put it into options.parentEl
		if (!this.options.parentEl) {
			if (this.options.appendToBody) {
				this.options.parentEl = this.bodyElement;
			} else {
				this.options.parentEl = this.renderer.parentNode(this.options.targetEl);
			}
		}
		this.contextMenuContainerEl = this.renderer.createElement('div');
		[
			'b-common_context_menu-container',
			this.getContextMenuElAttachmentClass(),
			...this.options.cssClasses,
			...this.options.cssAdditionalClasses,
		].forEach((className) => this.renderer.addClass(this.contextMenuContainerEl, className));
		this.renderer.appendChild(this.options.parentEl, this.contextMenuContainerEl);

		this.contextMenuEl = this.renderer.createElement('div');
		this.renderer.addClass(this.contextMenuEl, 'b-common_context_menu');
		this.renderer.appendChild(this.contextMenuContainerEl, this.contextMenuEl);

		if (this.options.shouldStopClickPropagation) {
			// should subscribe separately because domEventListeners are removed before click occurs
			// unsubscribe after AFTER_CLOSE event
			this.contextMenuEl.addEventListener('click', this.stopPropagation);
		}
		this.contextMenuBodyEl = this.renderer.createElement('div');
		this.renderer.addClass(this.contextMenuBodyEl, 'b-common_context_menu-content');
		this.renderer.appendChild(this.contextMenuEl, this.contextMenuBodyEl);

		// add mousedown event listener on next event loop tick to ignore current mousedown event
		// capture: true is required, otherwise the event does not reach the listener, if the element that triggered the event is destroyed
		setTimeout(() => {
			const listener = this.addEventListener(
				this.bodyElement,
				'mousedown',
				(event) => this.dismissClickHandler(event),
				{capture: true},
			);
			this.domEventListeners.push(listener);
		});

		this.open();
	}

	async open() {
		COMMON_CONTEXT_MENU_OPENED_FLAG.next(true);

		this.renderer.setStyle(this.contextMenuContainerEl, 'display', 'none');
		this.renderer.setStyle(this.contextMenuBodyEl, 'height', '');

		await this.portalScreen(this.activeScreenName);
		this.renderer.setStyle(this.activeScreenElem, 'display', 'block');
		this.reposition();
		this.resize();

		if (this.options.withOpenAnimate) {
			this.renderer.addClass(this.contextMenuContainerEl, 'b-common_context_menu-container--with_open_animate');
		}

		this.isOpen = true;
		this.renderer.addClass(this.options.targetEl, TARGET_EL_OPENED_CONTEXT_MENU_CLASS);

		if (this.options.scrollContainer) {
			const listener = this.renderer.listen(this.options.scrollContainer, 'scroll', () => {
				if (this.isOpen) {
					this.reposition();
				}
			});
			this.domEventListeners.push(listener);
		}

		{
			const listener = this.renderer.listen(this.contextMenuBodyEl, 'click', () => {
				this.emitEvent({
					type: COMMON_CONTEXT_MENU_EVENT_NAME.AFTER_CLICK,
				});
			});
			this.domEventListeners.push(listener);
		}

		{
			const listener = this.renderer.listen(this.contextMenuBodyEl, 'mouseenter', () => {
				this.emitEvent({
					type: COMMON_CONTEXT_MENU_EVENT_NAME.MOUSEENTER,
				});
			});
			this.domEventListeners.push(listener);
		}

		{
			const listener = this.renderer.listen(this.contextMenuBodyEl, 'mouseleave', () => {
				this.emitEvent({
					type: COMMON_CONTEXT_MENU_EVENT_NAME.MOUSELEAVE,
				});
			});
			this.domEventListeners.push(listener);
		}

		{
			const commonContentWrapperService = this.injector.get(CommonContentWrapperService);
			const subscription = merge(
				fromEvent(this.injector.get(DOCUMENT).defaultView, 'resize'),
				commonContentWrapperService.on(COMMON_CONTENT_WRAPPER_EVENT.AFTER_RESIZE),
			)
				.pipe(debounceTime(COMMON_THROTTLE_MILLISECONDS))
				.subscribe(() => {
					if (this.isOpen) {
						this.reposition();
					}
				});
			this.subscriptions.push(subscription);
		}

		await this.emitEventAfterAnimationFrame(
			{
				type: COMMON_CONTEXT_MENU_EVENT_NAME.AFTER_OPEN,
			},
			this.options.withOpenAnimate ? 300 : 0, // 300ms is the .b-common_context_menu-container--with_open_animate 's animation transition time
		);
	}

	// Subscription for sending and receiving events in the context menu
	onEvent(eventName: COMMON_CONTEXT_MENU_EVENT_NAME): Observable<ICommonContextMenuAction> {
		return this.getEventSubject(eventName).asObservable();
	}

	// Create custom event in ContextMenu
	emitCustomEvent(payload: ICommonAction<COMMON_CONTEXT_MENU_EVENT_NAME.CUSTOM>): void {
		this.emitEvent(payload);
	}

	// Gives current active screen
	getActiveScreen(): ICommonContextMenuScreen {
		return {
			isActive: !this.isInAnimate,
			name: this.activeScreenName,
			options: this.activeScreenOptions,
		};
	}

	// Is the context menu open
	isOpened(): boolean {
		return this.isOpen;
	}

	// Changes the screen to the default
	async changeScreenToDefault(animationName?: COMMON_CONTEXT_MENU_ANIMATION_DIRECTIONS): Promise<void> {
		if (!this.canChangeScreenTo('default')) return;

		await this.changeScreenTo('default', animationName);
		this.renderer.setStyle(this.activeScreenElem, 'display', 'block');
		this.reposition();
		this.resize();
	}

	// Changes the screen to a new one
	async changeScreenTo(
		screenName: TCommonContextMenuScreenName,
		animationName?: COMMON_CONTEXT_MENU_ANIMATION_DIRECTIONS,
	): Promise<void> {
		const oldHostPortal = this.hostPortal;
		const oldScreenElem = this.activeScreenElem;
		const animate = this.options.withChangeScreenAnimate ? animationName : false;

		if (this.canChangeScreenTo(screenName)) {
			this.isInAnimate = true;
			this.activeScreenName = screenName;

			await this.portalScreen(screenName);

			if (animate) {
				await this.defAnimates[animate](this.activeScreenElem, oldScreenElem, this.activeScreenOptions.heightControlling);

				oldHostPortal.detach();
			} else {
				oldHostPortal.detach();
				if (screenName !== 'default') {
					this.reposition();
				}
				this.resize();
			}
			this.isInAnimate = false;

			// run ngZone fake task for start component portal life cycle
			// otherwise rendering after the keyboard event will be with a mistake
			this.injector.get(NgZone).run(() => null);

			await this.emitEventAfterAnimationFrame({
				type: COMMON_CONTEXT_MENU_EVENT_NAME.AFTER_CHANGE_SCREEN,
			});
		} else {
			return Promise.reject(`Cannot to change screen (name: ${screenName})`);
		}
	}

	// Re-calculates the position for the context menu
	reposition (): void {
		let contextMenuRect: Rect;
		let isRightAlign;
		let coords: { left: string, top: string, right: string };
		const targetEl = this.options.targetEl;

		this.renderer.setStyle(this.contextMenuContainerEl, 'display', 'block');
		this.renderer.setStyle(this.contextMenuContainerEl, 'top', '-9999px');
		this.renderer.setStyle(this.contextMenuContainerEl, 'left', '-9999px');
		const contextMenuBodyRect = this.contextMenuBodyEl.getBoundingClientRect();
		const contextMenuSizes = new Point(contextMenuBodyRect.width, contextMenuBodyRect.height);
		const targetRect = this.calcTargetRect(targetEl as HTMLElement);
		const containerRect = this.calcContainerRect();
		contextMenuRect = this.calcContextMenuRect(targetRect, contextMenuSizes);
		const openUpDirection = this.checkAndUpdateOpenUpDirection(contextMenuRect, containerRect, targetRect);
		if (openUpDirection) {
			this.renderer.addClass(this.contextMenuContainerEl, 'b-common_context_menu--down_oriented');
		}

		contextMenuRect = this.applyDirection(contextMenuRect, targetRect, contextMenuSizes);
		[contextMenuRect, isRightAlign] = this.applyContainerRestrictions(contextMenuRect, containerRect, contextMenuSizes);
		if (isRightAlign) {
			this.renderer.addClass(this.contextMenuContainerEl, 'b-common_context_menu--right_align');
		} else {
			this.renderer.removeClass(this.contextMenuContainerEl, 'b-common_context_menu--right_align');
		}

		this.maxHeight = this.calcMaxHeight(contextMenuRect, containerRect);

		coords = this.getCoords(contextMenuRect, targetRect, isRightAlign);

		coords = this.applyContainerOffset(coords, isRightAlign);
		forIn(coords, (styleValue, styleName) => {
			this.renderer.setStyle(
				this.contextMenuContainerEl,
				styleName,
				(typeof styleValue === 'number') ? styleValue + 'px' : styleValue,
			);
		});
	}

	// Re-size context menu (with reposition or not)
	resize(withReposition?: boolean): void {
		const scrollElement = this.contextMenuBodyEl.querySelector('.b-common_context_menu-scroll');
		const scrollElementScrollTop: number = scrollElement && scrollElement.scrollTop;

		let height: number;
		let noScrollHeight: number;
		let activeScreenHeight: number;
		let activeScreenHeightControlling: boolean;
		let activeScreenIsHigh: boolean;

		if (scrollElement) this.renderer.setStyle(scrollElement, 'height', '');
		this.renderer.setStyle(this.contextMenuBodyEl, 'height', '');
		this.renderer.setStyle(this.contextMenuContainerEl, 'width', (this.options.targetEl as HTMLElement).getBoundingClientRect().width + 'px');

		this.renderer.addClass(this.activeScreenElem, CONTEXT_MENU_SCREEN_ELEMENT_CLASS);

		activeScreenHeight = this.activeScreenElem.getBoundingClientRect().height;
		activeScreenHeightControlling = this.activeScreenOptions.heightControlling;
		activeScreenIsHigh = this.maxHeight < activeScreenHeight;

		if (withReposition && (!activeScreenHeightControlling || (activeScreenHeightControlling && activeScreenIsHigh))) {
			this.reposition();
			this.resize(false);
		} else {
			if (activeScreenHeightControlling) {
				height = Math.min(activeScreenHeight, this.maxHeight);
			} else {
				height = activeScreenHeight;
			}

			noScrollHeight = this.calcNoScrollHeight();


			this.renderer.setStyle(this.contextMenuBodyEl, 'height', height + 'px');
			if (scrollElement) this.renderer.setStyle(scrollElement, 'height', (height - noScrollHeight) + 'px');
		}

		if (scrollElement && scrollElementScrollTop) {
			scrollElement.scrollTop = scrollElementScrollTop;
		}
	}

	// Closes and destroys the context menu
	close(): void {
		COMMON_CONTEXT_MENU_OPENED_FLAG.next(false);

		this.emitEvent({
			type: COMMON_CONTEXT_MENU_EVENT_NAME.BEFORE_CLOSE,
		});
		// remove rendered component
		this.hostPortal.detach();
		// destroy DOM container
		this.renderer.removeChild(
			this.renderer.parentNode(this.contextMenuContainerEl),
			this.contextMenuContainerEl,
		);
		// stop listening DOM events
		this.domEventListeners.forEach((listener) => listener());
		// unsubscribe subscriptions
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());

		this.renderer.removeClass(this.options.targetEl, TARGET_EL_OPENED_CONTEXT_MENU_CLASS);
		this.renderer.destroy();

		this.isOpen = false;

		// run ngZone fake task for start component portal life cycle
		this.injector.get(NgZone).run(() => null);

		this.emitEventAfterAnimationFrame({
			type: COMMON_CONTEXT_MENU_EVENT_NAME.AFTER_CLOSE,
		})
			.then(() => {
				this.eventSubjects.forEach((eventSubjects) => eventSubjects.complete());
				if (this.options.shouldStopClickPropagation) {
					this.contextMenuEl.removeEventListener('click', this.stopPropagation);
				}
			});

	}

	private emitEvent(payload: ICommonAction<COMMON_CONTEXT_MENU_EVENT_NAME>): void {
		if (this.options.events && this.options.events[payload.type]) {
			this.options.events[payload.type](payload);
		}
		this.getEventSubject(payload.type).next(payload);
	}

	private async emitEventAfterAnimationFrame(payload: ICommonAction<COMMON_CONTEXT_MENU_EVENT_NAME>, delayTime = 0): Promise<void> {
		await of(null)
			.pipe(
				delay(
					delayTime, // we need delay for waiting of animation
					animationFrameScheduler, // waiting next animationFrame after delay
				),
				observeOn(asyncScheduler), // put our code on next macroTasks queue (we need it because, our code will run before browser's rendering callbacks)
			)
			.toPromise();

		this.emitEvent(payload);
	}

	private getEventSubject(eventName: COMMON_CONTEXT_MENU_EVENT_NAME): Subject<ICommonContextMenuAction> {
		if (!this.eventSubjects.has(eventName)) this.eventSubjects.set(eventName, new Subject<ICommonContextMenuAction>());

		return this.eventSubjects.get(eventName);
	}

	private canChangeScreenTo(screen: TCommonContextMenuScreenName): boolean {
		return screen && this.activeScreenName !== screen && this.options.screens[screen] && !this.isInAnimate;
	}

	private dismissClickHandler (event) {
		let parentElmIsFind;

		if (this.isOpen) {
			parentElmIsFind = this.findParentElement(event.target, this.contextMenuContainerEl, this.preparedOptionsDismissClickIgnore);

			if (!parentElmIsFind) {
				// pass .close() method to next event loop tick for waiting complete current click event propagation
				setTimeout(() => {
					this.close();
				});
			}
		}
	}

	private convertToHTMLElement(element: TCommonContextMenuElementOrSelector): HTMLElement {
		if (typeof element === 'string') {
			return this.htmlElement.querySelector(element);
		} else if (element instanceof ElementRef) {
			return element.nativeElement;
		}

		return element;
	}

	private findParentElement (node: HTMLElement, element: HTMLElement, dismissClickIgnore: HTMLElement[]) {
		const compareWithNode = (x) => x === node;

		while (node !== this.htmlElement) {
			if (node && node !== element && !dismissClickIgnore.find(compareWithNode)) {
				node = this.renderer.parentNode(node);
			} else {
				return true;
			}
		}
		return false;
	}

	private createInjector(screenData?: any): PortalInjector {
		const injectorTokens = new WeakMap();

		if (screenData) injectorTokens.set(COMMON_CONTEXT_MENU_DATA, screenData);
		injectorTokens.set(COMMON_CONTEXT_MENU_ENTITY, this);

		if (Array.isArray(this.options.injectedTokens)) {
			for (const injectedToken of this.options.injectedTokens) {
				injectorTokens.set(injectedToken.token, injectedToken.value);
			}
		}

		return new PortalInjector(this.injector, injectorTokens);
	}

	private getContextMenuElAttachmentClass(): string {
		const attachmentToClassName = {
			[COMMON_CONTEXT_MENU_ATTACHMENTS.CENTER]: 'center',
			[COMMON_CONTEXT_MENU_ATTACHMENTS.LEFT]: 'left',
			[COMMON_CONTEXT_MENU_ATTACHMENTS.RIGHT]: 'right',
		};

		return 'b-common_context_menu--attachment_' + attachmentToClassName[this.options.attachment];
	}

	private findElementWithPosition (element: HTMLElement): HTMLElement {
		let position;

		if (element) {
			while (element && element !== this.htmlElement) {
				position = window.getComputedStyle(element).getPropertyValue('position');

				if (['relative', 'absolute', 'fixed'].includes(position) || element === this.bodyElement ) {
					return element;
				} else {
					element = this.renderer.parentNode(element);
				}
			}
		}

		return null;
	}

	private applyContainerOffset(coords, isRightAlign) {
		const element = this.findElementWithPosition(this.options.parentEl as HTMLElement);
		coords = clone(coords);

		if (element) {
			const offset = element.getBoundingClientRect();
			coords.top -= offset.top;

			if (!isRightAlign) {
				coords.left -= offset.left;
			}
		}

		return coords;
	}

	private getCoords(contextMenuRect, targetRect, isRightAlign) {
		let coordsTop;
		let left;
		let right;

		if (this.openUpDirection) {
			coordsTop = targetRect.lt.y - this.options.targetYOffset;
		} else {
			coordsTop = targetRect.rb.y + this.options.targetYOffset;
		}

		if (isRightAlign) {
			left = 'auto';
			right = this.options.containerXOffset;
		} else {
			left = contextMenuRect.lt.x + this.options.containerXOffset;
			right = 'auto';
		}

		return {
			top: coordsTop,
			left,
			right,
		};
	}

	private calcNoScrollHeight(): number {
		const noScrollElements: NodeListOf<HTMLElement> = this.contextMenuBodyEl.querySelectorAll(`.${COMMON_CONTEXT_MENU_NO_SCROLL_ELEMENT_CLASS}`);
		let noScrollHeight = 0;

		if (noScrollElements && noScrollElements.length) {
			noScrollElements.forEach( (el) => {
				noScrollHeight += el.offsetHeight;
			});
		}

		return noScrollHeight;
	}

	private calcMaxHeight(contextMenuRect, containerRect) {
		let maxHeight;

		if (this.openUpDirection) {
			maxHeight = contextMenuRect.rb.y - containerRect.lt.y - this.options.containerYOffset;
		} else {
			maxHeight = containerRect.rb.y - contextMenuRect.lt.y - this.options.containerYOffset;
		}

		return Math.min(maxHeight, MAX_CONTEXT_MENU_HEIGHT);
	}

	private applyContainerRestrictions(contextMenuRect, containerRect, contextMenuSizes) {
		const rightAlign = contextMenuRect.rb.x > containerRect.rb.x;
		const leftAlign = contextMenuRect.lt.x < containerRect.lt.x;

		if (rightAlign) {
			contextMenuRect.lt.x = containerRect.rb.x - contextMenuSizes.x - this.options.containerXOffset * 2;
			contextMenuRect.rb.x = containerRect.rb.x;
		} else if (leftAlign) {
			contextMenuRect.lt.x = containerRect.lt.x;
			contextMenuRect.rb.x = containerRect.lt.x + contextMenuSizes.x + this.options.containerXOffset * 2;
		}

		return [contextMenuRect, rightAlign];
	}

	private applyDirection(contextMenuRect, targetRect, contextMenuSizes) {
		if (this.openUpDirection) {
			contextMenuRect.lt.y = this.options.targetYOffset + targetRect.lt.y + contextMenuSizes.y;
			contextMenuRect.rb.y = this.options.targetYOffset + targetRect.lt.y;
		}

		return contextMenuRect;
	}

	private checkAndUpdateOpenUpDirection(contextMenuRect, containerRect, targetRect): boolean {
		if (this.openUpDirection) {
			return true;
		}

		const doesNotFitDown = contextMenuRect.rb.y > (containerRect.rb.y - this.options.containerYOffset);
		const preferredUp = this.options.verticalAttachment === COMMON_CONTEXT_MENU_VERTICAL_ATTACHMENTS.TOP;
		let openUp: boolean;

		if (this.activeScreenOptions.heightControlling) {
			const noScrollHeight = this.calcNoScrollHeight();
			const estimatedHeight = containerRect.rb.y - targetRect.rb.y;
			const expectedHeight = MIN_RECOMMENDED_CONTEXT_MENU_HEIGHT + noScrollHeight + this.options.containerYOffset;

			openUp = (doesNotFitDown || preferredUp) && (estimatedHeight < expectedHeight);
		} else {
			const canOpenUp = targetRect.lt.y > contextMenuRect.height + this.options.containerYOffset;

			openUp = (doesNotFitDown || preferredUp) && canOpenUp;
		}

		// if has once opened up - we save this situation that didn't jump up/down any more
		if (openUp) {
			this.openUpDirection = true;
		}

		return this.openUpDirection;
	}

	private calcContextMenuRect(targetRect, contextMenuSizes): Rect {
		const options = this.options;
		let leftPointX = 0;
		let rightPointX = 0;

		if (options.attachment === COMMON_CONTEXT_MENU_ATTACHMENTS.LEFT) {
			leftPointX = targetRect.lt.x + options.targetXOffset;
			rightPointX = targetRect.lt.x + contextMenuSizes.x + options.targetXOffset;

		} else if (options.attachment === COMMON_CONTEXT_MENU_ATTACHMENTS.CENTER) {
			leftPointX = targetRect.getCenter().x - contextMenuSizes.x / 2;
			rightPointX = targetRect.getCenter().x + contextMenuSizes.x / 2;

		} else if (options.attachment === COMMON_CONTEXT_MENU_ATTACHMENTS.RIGHT) {
			leftPointX = targetRect.rb.x - contextMenuSizes.x - options.targetXOffset;
			rightPointX = targetRect.rb.x  - options.targetXOffset;

		} else {
			console.error('CommonContextMenu.calcContextMenuRect() not have case for attachment `' + options.attachment + '`', options);
		}

		return new Rect(
			leftPointX - options.containerXOffset,
			options.targetYOffset + targetRect.rb.y,
			rightPointX + options.containerXOffset,
			options.targetYOffset * 2 + targetRect.rb.y + contextMenuSizes.y,
		);
	}

	private calcContainerRect() {
		const containerSize = this.getContainerSize();
		const bodyRect = new Rect(
			0,
			0,
			containerSize.width,
			containerSize.height,
		);
		const containersForPosition = this.preparedOptionsContainersForPosition;
		let maxBottom;
		let maxRight;
		let minLeft;
		let minTop;
		let containersForPositionRect;

		if (containersForPosition.length) {
			minLeft = Math.max(...containersForPosition.map((x) => {
				if (x.useLeft !== false) {
					return x.element.getBoundingClientRect().left;
				} else {
					return -Infinity;
				}
			}));
			maxRight = Math.min(...containersForPosition.map((x) => {
				if (x.useRight !== false) {
					return x.element.getBoundingClientRect().left + x.element.offsetWidth;
				} else {
					return Infinity;
				}
			}));
			minTop = Math.max(...containersForPosition.map((x) => {
				if (x.useTop !== false) {
					return x.element.getBoundingClientRect().top;
				} else {
					return -Infinity;
				}
			}));
			maxBottom = Math.min(...containersForPosition.map((x) => {
				if (x.useBottom !== false) {
					return x.element.getBoundingClientRect().top + x.element.offsetHeight;
				} else {
					return Infinity;
				}
			}));
			containersForPositionRect = new Rect(minLeft, minTop, maxRight, maxBottom);
		}

		return bodyRect
			.clone()
			.mergeRects(containersForPositionRect);
	}

	private getContainerSize() {
		return {
			width: document.documentElement.clientWidth,
			height: document.documentElement.clientHeight,
		};
	}

	private calcTargetRect(targetEl: HTMLElement) {
		const targetElOffset = targetEl.getBoundingClientRect();

		return new Rect(
			targetElOffset.left,
			targetElOffset.top,
			targetElOffset.left + targetElOffset.width,
			targetElOffset.top + targetElOffset.height,
		);
	}

	private async portalScreen(screenName: TCommonContextMenuScreenName): Promise<void> {
		const screen = this.options.screens[screenName];
		const portalInjector = this.createInjector((screenName === 'default' && this.options.data) || screen.data);
		this.hostPortal = new DomPortalOutlet(
			this.contextMenuBodyEl,
			this.injector.get(ComponentFactoryResolver),
			this.injector.get(ApplicationRef),
			portalInjector,
		);
		this.activeScreenComponentPortal = new ComponentPortal(this.options.screens[this.activeScreenName].component);
		this.activeScreenComponentRef = this.hostPortal.attach(this.activeScreenComponentPortal);
		this.activeScreenElem = this.activeScreenComponentRef.location.nativeElement;
		const promise = this.activeScreenComponentRef.instance.ngAfterViewInit$.toPromise();
		// run ngZone fake task for start component portal life cycle
		this.injector.get(NgZone).run(() => null);


		// hide new element and wait for new component and his children rendering complete
		this.renderer.setStyle(this.activeScreenElem, 'display', 'none');
		await promise;
		if (screenName !== 'default') {
			this.renderer.setStyle(this.activeScreenElem, 'display', 'block');
		}
	}

	private stopPropagation (event: Event) {
		event.stopPropagation();
	}

	private addEventListener (
		target: EventTarget,
		event: string,
		callback: EventListener,
		options?: boolean | AddEventListenerOptions,
	): TCallbackFunc {
		target.addEventListener(event, callback, options);

		return () => {
			target.removeEventListener(event, callback, options);
		};
	}
}
