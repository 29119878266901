export const COMMON_POPUP_CLASS_NAMES = {
	POPUP_HOST: 'b-popup-scroll_container',
	POPUP_SHOW: 'b-popup--show',
	POPUP_WITH_ANIMATION: 'b-popup--with_animation',
	POPUP_SLIDE_IN: 'b-popup--slide_in',
	POPUP_FADE_OUT: 'b-popup--fade_out',
	OVERLAY_HOST: 'b-popup-overlay',
	OVERLAY_HOST_SHOW: 'b-popup-overlay--show',
	BODY_HOST: 'b-popup-body',
	OVERLAY_BACKGROUND_HOST: 'b-popup-overlay-background',
	OVERLAY_LOADER: 'b-popup-loader_wrapper',
};
