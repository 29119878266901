export enum COMMON_POPUP_ACTION_TYPE {
	AFTER_OPEN = 'afterOpen',
	BEFORE_HIDE = 'beforeHide',
	AFTER_HIDE = 'afterHide',
	BEFORE_SHOW = 'beforeShow',
	AFTER_SHOW = 'afterShow',
	BEFORE_CLOSE = 'beforeClose',
	AFTER_CLOSE = 'afterClose',
	CUSTOM = 'custom',
	CLICK_ADDITION_TITLE = 'COMMON_POPUP_ACTION_TYPE.CLICK_ADDITION_TITLE',
}
