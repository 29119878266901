import { InjectionToken } from '@angular/core';

export interface ICommonAppConfig {
	name: string,
	shouldAuthorize: boolean,
	mainComponent?,
	bootstrapApiUrl?: string,
}

export const COMMON_APP_CONFIG = new InjectionToken<ICommonAppConfig>('appConfig');
