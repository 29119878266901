import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	SimpleChanges,
} from '@angular/core';
import { CommonBaseComponent } from '../../base_component/base.component';

@Component({
	selector: 'common-form-submitters',
	templateUrl: './common-form-submitters.component.pug',
	styleUrls: [
		'./common-form-submitters.component.sass',
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonFormSubmittersComponent extends CommonBaseComponent {
	@Input() isCreateForm: boolean;  // edit or create form
	@Input() isReadonly: boolean = false;  // use with readonly forms
	@Input() isDisabled: boolean = false;  // use with invalid forms, but available for saving

	@Input() submitTitleEdit: string = '';
	@Input() submitTitleCreate: string = '';
	@Input() cancelTitleEdit: string = '';
	@Input() cancelTitleCreate: string = '';

	@Output() onSubmit = new EventEmitter<Event>();
	@Output() onCancel = new EventEmitter<Event>();

	submitTitle: string = '';
	cancelTitle: string = '';

	ngOnChanges(changes: SimpleChanges) {
		super.ngOnChanges(changes);

		this.setBtnTitles();
	}

	onSubmitActionHandler($event: Event) {
		this.onSubmit.emit($event);
	}

	onCancelClickOrEnterOrSpaceHandler($event: Event) {
		this.onCancel.emit($event);
	}

	private setBtnTitles () {
		if (this.isReadonly) {
			const DEFAULT_CANCEL_TITLE_READONLY = this.instant('common.close');

			this.cancelTitle = this.cancelTitleEdit || this.cancelTitleCreate || DEFAULT_CANCEL_TITLE_READONLY;
		} else {
			const DEFAULT_SUBMIT_TITLE_CREATE = this.instant('common.add');
			const DEFAULT_SUBMIT_TITLE_EDIT = this.instant('common.save');
			const DEFAULT_CANCEL_TITLE = this.instant('common.cancel');

			if (this.isCreateForm) {
				this.submitTitle = this.submitTitleCreate || DEFAULT_SUBMIT_TITLE_CREATE;
				this.cancelTitle = this.cancelTitleCreate || DEFAULT_CANCEL_TITLE;
			} else {
				this.submitTitle = this.submitTitleEdit || DEFAULT_SUBMIT_TITLE_EDIT;
				this.cancelTitle = this.cancelTitleEdit || DEFAULT_CANCEL_TITLE;
			}
		}
	}
}
