import { Pipe, PipeTransform } from '@angular/core';

import { capitalize } from 'lodash';

import { CommonHotkeyService } from '../hotkey/services/hotkey.service';

@Pipe({
	name: 'commonHotkeyInfo',
})
export class CommonHotkeyInfoPipe implements PipeTransform {
	constructor(
		private commonHotkeyService: CommonHotkeyService,
	) {}

	transform(text, hotkeyConfigName) {
		let hotkeyCombo = this.commonHotkeyService.getComboByConfigName(hotkeyConfigName);

		if (!hotkeyCombo) {
			return text;
		} else {
			hotkeyCombo = hotkeyCombo.split('+').map((comboPart) => {
				return capitalize(comboPart);
			}).join('+');
		}

		return `${text} (${hotkeyCombo})`;
	}
}
