// New version src/Common/pipes/common-recipients-list-formatter.pipe.ts
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/common_recipients_list_formatter_filter.ts
import { Pipe, PipeTransform } from '@angular/core';
import { CommonListFormatterPipe } from './common-list-formatter.pipe';

@Pipe({
	name: 'commonRecipientsListFormatter',
})
export class CommonRecipientsListFormatterPipe implements PipeTransform {
	constructor (
		private commonListFormatter: CommonListFormatterPipe,
	) {}

	transform(list: any[], placeholder: string = ''): string {
		return this.commonListFormatter.transform(list, {
			placeholder,
			fields: ['Name', 'Email'],
		});
	}
}
