import { ElementRef, InjectionToken } from '@angular/core';

import { ICommonAction } from '../../interfaces/core';
import { CommonContextMenuBaseScreenComponent } from './components/common-context-menu-base-screen.component';
import { ComponentType } from '@angular/cdk/overlay';


export enum COMMON_CONTEXT_MENU_EVENT_NAME {
	AFTER_OPEN = 'COMMON_CONTEXT_MENU_EVENT_NAME.AFTER_OPEN',
	AFTER_CHANGE_SCREEN = 'COMMON_CONTEXT_MENU_EVENT_NAME.AFTER_CHANGE_SCREEN',
	AFTER_CLICK = 'COMMON_CONTEXT_MENU_EVENT_NAME.AFTER_CLICK',
	BEFORE_CLOSE = 'COMMON_CONTEXT_MENU_EVENT_NAME.BEFORE_CLOSE',
	AFTER_CLOSE = 'COMMON_CONTEXT_MENU_EVENT_NAME.AFTER_CLOSE',
	MOUSEENTER = 'COMMON_CONTEXT_MENU_EVENT_NAME.MOUSEENTER',
	MOUSELEAVE = 'COMMON_CONTEXT_MENU_EVENT_NAME.MOUSELEAVE',
	CUSTOM = 'COMMON_CONTEXT_MENU_EVENT_NAME.CUSTOM',
}

export enum COMMON_CONTEXT_MENU_ANIMATION_DIRECTIONS {
	LEFT_TO_RIGHT = 'COMMON_CONTEXT_MENU_ANIMATION_DIRECTIONS.LEFT_TO_RIGHT',
	RIGHT_TO_LEFT = 'COMMON_CONTEXT_MENU_ANIMATION_DIRECTIONS.RIGHT_TO_LEFT',
}

export enum COMMON_CONTEXT_MENU_ATTACHMENTS {
	RIGHT = 'COMMON_CONTEXT_MENU_ATTACHMENTS.RIGHT',
	LEFT = 'COMMON_CONTEXT_MENU_ATTACHMENTS.LEFT',
	CENTER = 'COMMON_CONTEXT_MENU_ATTACHMENTS.CENTER',
}

export enum COMMON_CONTEXT_MENU_VERTICAL_ATTACHMENTS {
	TOP = 'COMMON_CONTEXT_MENU_VERTICAL_ATTACHMENTS.TOP',
	BOTTOM = 'COMMON_CONTEXT_MENU_VERTICAL_ATTACHMENTS.BOTTOM',
}

export interface ICommonContextMenuScreen {
	name: string,
	isActive: boolean,
	options: ICommonContextMenuOptionsScreen,
}

export interface ICommonContextMenuAction extends ICommonAction<COMMON_CONTEXT_MENU_EVENT_NAME> {}

export type TCommonContextMenuElement = ElementRef | HTMLElement;
export type TCommonContextMenuElementOrSelector = string | TCommonContextMenuElement;
export type TCommonContextMenuScreenName = 'default' | string;

export interface ICommonContextMenuOptionsScreen<ComponentT = CommonContextMenuBaseScreenComponent> {
	component: ComponentType<ComponentT>, // Component to display on this screen
	name?: TCommonContextMenuScreenName, // Screen selection key
	heightControlling?: boolean, // Do you want this screen to adjust to the size of the browser Default - false
	data?: any,
}

export interface ICommonContextMenuOptionsContainerForPosition {
	element: TCommonContextMenuElementOrSelector, // The selector or element to be used as the border
	useLeft?: boolean, // Default - true
	useRight?: boolean, // Default - true
	useTop?: boolean, // Default - true
	useBottom?: boolean, // Default - true
}

export interface ICommonContextMenuOptions<DataType = any> {
	id?: string,  // Close other context menus with same ids before open
	targetEl?: TCommonContextMenuElement, // The purpose for which the context menu is positioned
	screens?: Record<TCommonContextMenuScreenName, ICommonContextMenuOptionsScreen>, // Screens in menu

	data?: DataType, // Data that will be available in components within the menu
	parentEl?: TCommonContextMenuElement, // The item to which the context menu will be added, Default - targetEl.parent()
	appendToBody?: boolean, // If true, set 'body' into parentEl
	containersForPosition?: ICommonContextMenuOptionsContainerForPosition[], // Elements restricting the positioning of the context menu
	scrollContainer?: TCommonContextMenuElementOrSelector, // targetEl.closest() - An element used to bind scroll events and move the context menu
	cssClasses?: string[], // Stylistic classes for the root menu item
	cssAdditionalClasses?: string[], // Stylistic classes additional for cssClasses for the root menu item
	dismissClickIgnore?: TCommonContextMenuElementOrSelector[], // What elements do not respond to closure
	closeOtherContextMenu?: boolean, // Close other context menus? Default - true
	withChangeScreenAnimate?: boolean, // Whether to use animation to change screen, Default - false
	withOpenAnimate?: boolean, // Whether to use animation to open, Default - false
	attachment?: COMMON_CONTEXT_MENU_ATTACHMENTS, // Which side of the context menu to stick to the target
	verticalAttachment?: COMMON_CONTEXT_MENU_VERTICAL_ATTACHMENTS,  // Which vertical side of the context menu to stick to the target
	containerXOffset?: number, // Default - 0
	containerYOffset?: number, // Default - 64
	targetXOffset?: number, // Default - 0
	targetYOffset?: number, // Default - 0
	events?: Partial<Record<COMMON_CONTEXT_MENU_EVENT_NAME, (event: ICommonContextMenuAction) => void>> // Automatic Binding and Event Capturing
	injectedTokens?: Array<{
		token: InjectionToken<any>,
		value: any,
	}>, // Tokens passed to created components inside the context menu
	shouldStopClickPropagation?: boolean,
}

// Interface for component with context menu for transfer options
export interface ICommonComponentWithContextMenu {
	contextMenuOptions: Partial<ICommonContextMenuOptions>;

	// Open the context menu, will be used through Ref
	openContextMenu();

	// Closes the context menu, will be used through Ref
	closeContextMenu();
}
