import { COMMON_MASK_ERROR_KEY } from '@CaseOne/Common/control_validators/mask/mask.validator';

export const COMMON_PATTERN_ERROR_KEY = 'CommonPatternValidator';

export const DEFAULT_ERROR_MESSAGES = {
	min: 'common.validation.min_common',
	max: 'common.validation.max_common',
	minLength: 'common.validation.minlength_common',
	maxLength: 'common.validation.maxlength_common',
	[COMMON_PATTERN_ERROR_KEY]: 'common.validation.pattern',
	[COMMON_MASK_ERROR_KEY]: 'common.validation.mask',
	incorrectDate: 'common.validation.incorrectDate',
	email: 'common.validation.email',
};
