import { Directive, Injector, Type } from '@angular/core';
import {CommonLocaleService} from '../locale/locale.service';
import {ICommonDataSourceServiceOptions} from '../controls/data-source.service/common-data-source.service';
import {ICommonListResourceService} from '../base-list-resource.service/common-base-list-resource.service';
import {CommonBaseDictionaryService} from '../controls/base-dictionary.service/common-base-dictionary.service';

@Directive()
export class CommonBaseEntityClassWithoutInjectable {
	protected commonLocaleService = this.injector.get(CommonLocaleService);

	constructor(
		protected injector: Injector,
	) {}

	protected instant(key: string, params?: {}): string {
		return this.commonLocaleService.instant(key, params);
	}

	protected getSrcServiceAsDataSource (
		serviceType: Type<CommonBaseDictionaryService | ICommonListResourceService>,
		options?: Partial<ICommonDataSourceServiceOptions>,
	): ICommonDataSourceServiceOptions {
		return {
			srcService: this.injector.get<CommonBaseDictionaryService | ICommonListResourceService>(serviceType),
			...options,
		};
	}
}

// tslint:disable:max-classes-per-file
@Directive()
export class CommonBaseEntityClass extends CommonBaseEntityClassWithoutInjectable {
	constructor(
		protected injector: Injector,
	) {
		super(injector);
	}
}
