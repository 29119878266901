import { NgModule } from '@angular/core';
import * as jQuery from 'jquery';

@NgModule({
})
export class CommonVendorModule {
	constructor (
	) {
		// export jQuery as global variable for external libs
		window.$ = window.jQuery = jQuery;
	}
}
