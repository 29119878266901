import { ViewChild, Directive, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormGroup, FormGroupDirective, NgForm } from '@angular/forms';

import { distinctUntilChanged, finalize, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, merge, of } from 'rxjs';

import { CommonBaseComponent } from '../../base_component/base.component';
import { ICommonResponse } from '../../interfaces/response';
import { CommonControlComponent } from '../../controls/control/control.class';
import { remove, filter, sortBy } from 'lodash';
import { Rect } from '../../utilities/rect';
import { CommonFormSubmittersComponent } from '@CaseOne/Common/form/common-form-submitters.component/common-form-submitters.component';


export const SCROLL_TOP_OFFSET = 128;

export interface ICommonFormSubmitRequestOptions<Response> {
	hideLoaderOnlyOnError?: boolean,  // we do not unlock the form if after saving it is no longer needed (IE bug with mass submit)
	onSuccess?: (response?: Response) => any,
	onError?: (response?: Response) => any,
	onFinalize?: () => any,
}

export interface ICommonFormState {
	isSaving: boolean;
	isChanged: boolean;
	isDisabled: boolean;
	isReadonly: boolean;
	isDirty: boolean;
}


@Directive()
export abstract class CommonFormComponent<StateType extends ICommonFormState = ICommonFormState> extends CommonBaseComponent {
	@Input() isSubmitted: boolean;

	@Input() isValid: boolean;
	@Output() isValidChange: EventEmitter<boolean> = new EventEmitter();
	@Input() isInvalid: boolean;
	@Output() isInvalidChange: EventEmitter<boolean> = new EventEmitter();

	@ViewChild(NgForm, { static: true }) form: NgForm;
	@ViewChild(FormGroupDirective, { static: true }) formGroup: FormGroupDirective;
	@ViewChild(CommonFormSubmittersComponent, { static: true }) submittersComponent: CommonFormSubmittersComponent;

	public blurValidationEnabled = true;

	public state$: BehaviorSubject<StateType> = new BehaviorSubject<StateType>({
		isSaving: false,
		isChanged: false,
		isDisabled: false,
		isReadonly: false,
		isDirty: false,
	} as StateType);
	public isSubmitButtonDisabled$: Observable<boolean>;
	public reactiveForm = new FormGroup({});

	protected scrollContainerSelector = 'html, body';
	protected controls: CommonControlComponent[] = [];

	private changes$: Observable<[StateType, any, any]>;

	private get formGetter(): NgForm | FormGroupDirective {
		return this.form || this.formGroup;
	}

	ngOnChanges(changes: SimpleChanges) {
		super.ngOnChanges(changes);

		if (changes.isSubmitted?.currentValue && this.formGetter.form && !this.formGetter.submitted) {
			this.submitForm();
		}
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();

		if (this.isSubmitted && !this.formGetter.submitted) {
			this.submitForm();
		}

		this.changes$ = merge(
			this.state$,
			this.formGetter.ngSubmit,
			this.formGetter.statusChanges,
		);

		this.isSubmitButtonDisabled$ = this.changes$.pipe(
			map(() => {
				const { isSaving, isDisabled } = this.state$.getValue();
				const { submitted, invalid } = this.formGetter;

				return isSaving || isDisabled || submitted && invalid;
			}),
			distinctUntilChanged(),
		);

		// highlight invalid fields in forms without submit button after blur/change
		if (!this.submittersComponent) {
			this.changes$
				.pipe(
					map(() => {
						const { isDirty } = this.state$.getValue();
						const { valid } = this.formGetter;

						return isDirty && !valid;
					}),
					distinctUntilChanged(),
					this.takeUntilDestroy(),
				)
				.subscribe((isDirtyAndInvalid) => {
					if (isDirtyAndInvalid) {
						this.submitForm();
					}
				});
		}

		this.subscribe(
			this.changes$,
			() => {
				this.isValidChange.emit(this.formGetter.valid);
				this.isInvalidChange.emit(this.formGetter.invalid);

				// Wait full rendering form CASEM-63898
				this.setTimeout(() => {
					this.runUpdate();
				});
			},
		);

		// Only used inside reactive forms. Inside template-driven forms fires after init
		if (this.formGroup) {
			this.formGroup.valueChanges
				.pipe(this.takeUntilDestroy())
				.subscribe((a) => {
					if (!this.state$.getValue().isChanged) {
						this.setState({ isChanged: true } as Partial<StateType>);
					}
				});
		}
	}

	onSubmitForm = (): any  => {
		if (this.formGetter.valid) {
			this.onValidSubmit();

		} else {
			// Wait change state in controls
			this.setTimeout(() => {
				this.scrollToInvalidControl();
				this.runUpdate();
			});
		}
	}

	scrollToInvalidControl(): void {
		const invalidControls = filter(this.controls, (c) => !c.isValid);
		const sortedControls = sortBy(invalidControls, (c) => new Rect(c.elementRef.nativeElement).lt.y);
		const firstInvalidControl = sortedControls[0];

		if (firstInvalidControl) {
			const controlRect = new Rect(firstInvalidControl.elementRef.nativeElement);

			if (controlRect.lt.y < 0) {
				const scrollContainer = document.querySelector(this.scrollContainerSelector);
				scrollContainer.scrollTop += controlRect.lt.y - SCROLL_TOP_OFFSET;
			}

			if (window.innerHeight < controlRect.rb.y) {
				const scrollContainer = document.querySelector(this.scrollContainerSelector);
				scrollContainer.scrollTop += (controlRect.rb.y - window.innerHeight) + SCROLL_TOP_OFFSET;
			}
		}
	}

	registerControl(control: CommonControlComponent) {
		// highlight invalid fields in forms without submit button after blur/change
		if (!this.submittersComponent && this.blurValidationEnabled) {
			merge(
				control.onBlur,
				control.onChange,
			)
				.pipe(this.takeUntilDestroy())
				.subscribe(() => {
					this.setState({ isDirty: true } as Partial<StateType>);
				});
		}

		this.controls.push(control);
	}

	removeControl(control: CommonControlComponent) {
		remove(this.controls as any, (c) => c === control);
	}

	submitForm = (): void => {
		this.formGetter.onSubmit(undefined);
	}

	protected setState = (state: Partial<StateType>): void => {
		this.state$.next({
			...this.state$.getValue(),
			...state,
		});
	}

	protected getState = (): StateType => {
		return {
			...this.state$.getValue(),
		};
	}

	protected abstract onValidSubmit(): Observable<any> | void;

	protected sendRequest<Response extends ICommonResponse<any>> (
		request$: Observable<Response>,
		options: ICommonFormSubmitRequestOptions<Response> = {},
	): Observable<Response> {
		this.setState({ isSaving: true } as Partial<StateType>);

		request$
			.pipe(
				this.takeUntilDestroy(),
				finalize(() => {
					if (options.onFinalize) {
						options.onFinalize();
					}

					if (!options.hideLoaderOnlyOnError) {
						this.setState({ isSaving: false } as Partial<StateType>);
					}
				}),
			)
			.subscribe((response) => {
				if (response && response.IsSuccess) {
					this.setState({ isChanged: false, isDirty: false } as Partial<StateType>);

					if (options.onSuccess) {
						options.onSuccess(response);
					}
				} else {
					if (options.hideLoaderOnlyOnError) {
						this.setState({ isSaving: false } as Partial<StateType>);
					}

					if (options.onError) {
						options.onError(response);
					}
				}
			}, (err) => {
				this.setState({ isSaving: false } as Partial<StateType>);

				if (options.onError) {
					options.onError(err);
				}

				return err;
			});

		return request$;
	}
}
