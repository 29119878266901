import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonValidationComponent } from './validation.component';
import { CommonMinLengthValidatorModule } from '../../../control_validators/minlength/minlength.module';
import { CommonMaxLengthValidatorModule } from '../../../control_validators/maxlength/maxlength.module';
import { CommonRequiredValidatorModule } from '../../../control_validators/required/required.module';
import { CommonPatternValidatorModule } from '../../../control_validators/pattern/pattern.module';
import { CommonEmailValidatorModule } from '../../../control_validators/email/email.module';
import { CommonMatchValidatorModule } from '../../../control_validators/match/match.module';
import { CommonMultipleFieldRequiredValidatorModule } from '../../../control_validators/multiple-field-required/multiple-field-required.module';
import { CommonRequiredIdValidatorModule } from '../../../control_validators/required-id.directive/required-id.module';
import { CommonMinValidatorModule } from '../../../control_validators/min/min.module';
import { CommonMaxValidatorModule } from '../../../control_validators/max/max.module';
import { CommonBackEndValidatorModule } from '@CaseOne/Common/control_validators/back-end/back-end.module';


@NgModule({
	imports: [
		CommonModule,
		CommonMinLengthValidatorModule,
		CommonMaxLengthValidatorModule,
		CommonRequiredValidatorModule,
		CommonRequiredIdValidatorModule,
		CommonPatternValidatorModule,
		CommonEmailValidatorModule,
		CommonMatchValidatorModule,
		CommonMultipleFieldRequiredValidatorModule,
		CommonMinValidatorModule,
		CommonMaxValidatorModule,
		CommonBackEndValidatorModule,
	],
	exports: [
		CommonValidationComponent,
		CommonMinLengthValidatorModule,
		CommonMaxLengthValidatorModule,
		CommonRequiredValidatorModule,
		CommonRequiredIdValidatorModule,
		CommonPatternValidatorModule,
		CommonEmailValidatorModule,
		CommonMatchValidatorModule,
		CommonMultipleFieldRequiredValidatorModule,
		CommonMinValidatorModule,
		CommonMaxValidatorModule,
		CommonBackEndValidatorModule,
	],
	declarations: [
		CommonValidationComponent,
	],
	entryComponents: [
		CommonValidationComponent,
	],
})
export class CommonValidationModule {}
