import { Pipe, PipeTransform } from '@angular/core';

import { CommonTwoDigitsNumberPipe } from './two_digits_number.pipe';

@Pipe({
	name: 'commonSecondsFormatter',
})
export class CommonSecondsFormatterPipe implements PipeTransform {
	constructor(private commonTwoDigitsNumberPipe: CommonTwoDigitsNumberPipe) {}

	transform (seconds: number) {
		const m = Math.floor(seconds / 60);
		const s = Math.floor(seconds % 60);

		if (!seconds) {
			return seconds;
		}

		return [this.commonTwoDigitsNumberPipe.transform(m), this.commonTwoDigitsNumberPipe.transform(s)].join(':');
	}
}
