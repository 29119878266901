import {
	CommonBaseEntityClassWithoutInjectable,
} from '../common-base-entity.class/common-base-entity.class';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Directive, Injector } from '@angular/core';

@Directive()
export class CommonBaseServiceWithoutInjectable extends CommonBaseEntityClassWithoutInjectable {
	protected destroy$ = new Subject();

	takeUntilDestroy() {
		return takeUntil(this.destroy$);
	}

	destroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}


// tslint:disable:max-classes-per-file
@Directive()
export class CommonBaseService extends CommonBaseServiceWithoutInjectable {
	constructor(
		protected injector: Injector,
	) {
		super(injector);
	}
}
