import { NgModule } from '@angular/core';
import { CommonGeneralSettingsService } from './general_settings.service';
import { CommonGeneralSettingsDaysOfWeekModule } from './days-of-week/general-settings-days-of-week.module';

@NgModule({
	imports: [
		CommonGeneralSettingsDaysOfWeekModule,
	],
	exports: [
		CommonGeneralSettingsDaysOfWeekModule,
	],
	providers: [
		CommonGeneralSettingsService,
	],
})
export class CommonGeneralSettingsModule {}
