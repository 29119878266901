import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonChooseTrueValidatorDirective} from './choose_true.directive';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
	],
	exports: [
		CommonChooseTrueValidatorDirective,
	],
	declarations: [
		CommonChooseTrueValidatorDirective,
	],
	entryComponents: [],
})
export class CommonChooseTrueValidatorModule {}
