import { TransitionService } from '@uirouter/core';
import { CommonAppDataService } from '../../../Common/app_data/app_data.service';

export function registrationLinkErrorHook (transitionService: TransitionService) {
	const criteria = {
		from: '',
		to: (state, transition) => {
			const commonAppDataService: CommonAppDataService = transition.injector().get(CommonAppDataService);
			const registrationErrorTitle = commonAppDataService.getDataByPath('registrationErrorTitle');
			const registrationErrorText = commonAppDataService.getDataByPath('registrationErrorText');

			return state.name === 'login' && (registrationErrorTitle || registrationErrorText);
		},
	};

	function redirectToRegistration (transition) {
		return transition.router.stateService.target('registration');
	}

	transitionService.onBefore(criteria, redirectToRegistration);
}

