import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CommonRequiredIdDirective } from './common-required-id.directive';
import { CommonModule } from '@angular/common';
import { CommonRequiredIdValidator } from './common-required-id.validator';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
	],
	providers: [
		CommonRequiredIdValidator,
	],
	exports: [
		CommonRequiredIdDirective,
	],
	declarations: [
		CommonRequiredIdDirective,
	],
})
export class CommonRequiredIdValidatorModule {}
