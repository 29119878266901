import { Directive, Input } from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import { commonUtilitiesCoreService } from '../../utilities/core.service';

export const COMMON_MULTIPLE_FIELD_REQUIRED_ERROR_KEY = 'CommonMultipleFieldRequired';

@Directive({
	selector: '[commonMultipleFieldRequired]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: CommonMultipleFieldRequiredDirective,
		multi: true,
	}],
})
export class CommonMultipleFieldRequiredDirective implements Validator {
	@Input('commonMultipleFieldRequired') enabled: boolean;
	@Input() minModelField: string;
	@Input() maxModelField: string;

	validate(control: AbstractControl): ValidationErrors | null {
		const valueIsEmpty =
			!commonUtilitiesCoreService.hasValue(control.value)
			|| (
				!commonUtilitiesCoreService.hasValue(control.value[this.minModelField])
				&& !commonUtilitiesCoreService.hasValue(control.value[this.maxModelField])
			);

		if (this.enabled !== false && valueIsEmpty) {
			return { [COMMON_MULTIPLE_FIELD_REQUIRED_ERROR_KEY]: true };
		} else {
			return null;
		}
	}
}
