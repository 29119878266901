import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonUserDataService } from './user_data.service';
import { COMMON_APP_CONFIG, ICommonAppConfig } from '../app/app.config.token';

export function initCommonUserDataService (
	commonUserDataService: CommonUserDataService,
	appConfig: ICommonAppConfig,
) {
	return () => {
		return commonUserDataService.init(appConfig.shouldAuthorize).toPromise();
	};
}

@NgModule({
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initCommonUserDataService,
			multi: true,
			deps: [
				CommonUserDataService,
				COMMON_APP_CONFIG,
			],
		},
	],
})
export class CommonUserDataModule {}
