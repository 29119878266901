// New version src/Common/url_filters/filters_to_url.service.ts
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/services/filters_to_url_service.js

import {
	StateService,
	TransitionPromise,
} from '@uirouter/core';
import { Injectable } from '@angular/core';
import {
	clone,
	isObject,
	isDate,
	isArray,
	forOwn,
	omitBy,
} from 'lodash';

import { CommonServerDateService } from '../server_date/server_date.service';

@Injectable()
export class CommonFiltersToUrlService {
	static setQueryParam(params, stateService: StateService): TransitionPromise {
		return stateService.transitionTo(stateService.current, params, {
			reload: false,
			inherit: true,
		});
	}

	constructor(
		private commonServerDateService: CommonServerDateService,
		private stateService: StateService,
	) {}

	public set(filter, options): TransitionPromise {
		const newFilter = {};
		filter = omitBy(clone(filter), (item) => {
			return item === '';
		});

		for (let j = 0, len1 = options.length; j < len1; j++) {
			const option = options[j];

			if (option.isObject && filter[option.key]) {
				const nonEmptyValue = Object.values(filter[option.key]).find((valueItem) => !!valueItem);

				newFilter[option.key] = nonEmptyValue ? encodeURI(JSON.stringify(filter[option.key])) : null;
			} else if (isObject(filter[option.key]) && !isDate(filter[option.key]) && !isArray(filter[option.key])) {
				forOwn(filter[option.key], (subValue, subKey) => {
					const { key, value } = this.parseFilters(subKey, option, filter[option.key], option.key);
					newFilter[key] = value;
				});
			} else {
				const { key, value } = this.parseFilters(option.key, option, filter);
				newFilter[key] = value;
			}
		}

		return CommonFiltersToUrlService.setQueryParam(newFilter, this.stateService);
	}

	private parseFilters(key, option, filter, parentPath?) {
		let value = clone(filter[key]);

		if (parentPath) {
			key = parentPath + '.' + key;
		}

		if (option.isObject && value) {
			const nonEmptyValue = value.find((valueItem) => !!valueItem);

			value = nonEmptyValue ? encodeURI(JSON.stringify(value)) : null;
		} else {
			if (isArray(value)) {
				value = clone(value);

				for (let i = 0, arrI = 0, arrLen = value.length; arrI < arrLen; i = ++arrI) {
					const item = value[i];

					if (item === true) {
						value[i] = 'true';
					}
				}
			}

			if (option.isNull && value === null) {
				value = 'null';
			}

			if (option.isDate && this.commonServerDateService.isDateString(value)) {
				value = this.commonServerDateService.toDateString(value);
			}

			return { key, value };
		}
	}
}
