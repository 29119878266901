import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	ViewEncapsulation,
} from '@angular/core';
import {
	COMMON_ACTIONS_DROPDOWN_LABEL_TYPE,
	ICommonActionsDropdownComponentAction,
} from '../../common-actions-dropdown.interfaces';

@Component({
	selector: 'common-actions-dropdown-option',
	templateUrl: './common-actions-dropdown-option.component.pug',
	styleUrls: ['./common-actions-dropdown-option.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class CommonActionsDropdownOptionComponent<EntityType> {
	@Input() option: ICommonActionsDropdownComponentAction<EntityType>;
	@Input() isActive: boolean;

	labelTypes = COMMON_ACTIONS_DROPDOWN_LABEL_TYPE;

	constructor (
		public elementRef: ElementRef,
	) {}

	getOptionItemClasses(): string {
		const classes = [];

		if (this.option.iconClasses) {
			classes.push('b-actions-dropdown-menu-list-item--with_icon');
		}
		if (this.option.screen) {
			classes.push('b-actions-dropdown-menu-list-item--with_arrow');
		}
		if (this.isActive) {
			classes.push('b-actions-dropdown-menu-list-item--active');
		}

		return classes.join(' ');
	}
}
