import { CommonBaseComponent } from '../base_component/base.component';
import { EventEmitter, HostListener, Output, Directive } from '@angular/core';

@Directive()
export class CommonFocusedBaseComponent extends CommonBaseComponent {
	@Output() onFocus = new EventEmitter<Event>();
	@Output() onBlur = new EventEmitter<Event>();

	isFocused: boolean = false;

	@HostListener('focus') onFocusHandler(event: Event): void {
		this.isFocused = true;
		this.onFocus.emit(event);
	}

	@HostListener('blur') onBlurHandler(event: Event): void {
		this.isFocused = false;
		this.onBlur.emit(event);
	}
}
