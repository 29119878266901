export const COMMON_HOT_KEYS = {
	LEFT_MOUSE: 1,
	MIDDLE_MOUSE: 2,
	RIGHT_MOUSE: 3,
	ARROW_UP: 38,
	ARROW_DOWN: 40,
	ARROW_RIGHT: 39,
	ARROW_LEFT: 37,
	ENTER: 13,
	ESC: 27,
	TAB: 9,
	END: 35,
	HOME: 36,
	BACKSPACE: 8,
	DELETE: 46,
	SPACE: 32,
};

