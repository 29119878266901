import {
	isString,
	clone,
	forEach,
} from 'lodash';

export type CommonDebugServiceFieldType = 'boolean' | 'string' | 'number' | 'function' | 'interface' | 'select';

export interface ICommonDebugComponentServiceStateConfig {
	name?: string,
	type: CommonDebugServiceFieldType,
	field?: string,
	description?: string,
	defaultValue?: any,
	get?: (component: any) => any,
	set?: (component: any, value: any) => any,
}

export interface ICommonDebugComponentServiceProps {
	[key: string]: ICommonDebugComponentServiceStateConfig,
}

export interface ICommonDebugComponentServiceStates {
	[key: string]: ICommonDebugComponentServiceStateConfig,
}

export interface ICommonDebugComponentServiceTest {
	type: 'describe' | 'it',
	level: number,
	text: string,
}

export interface ICommonDebugComponentServiceConfig {
	name: string,
	designLink?: string,
	description: string,
	testsFile?: string,
	tests?: ICommonDebugComponentServiceTest[],
	props: ICommonDebugComponentServiceProps,
	states: ICommonDebugComponentServiceStates,
}

export class CommonDebugComponentService {
	static parseField (
		state: ICommonDebugComponentServiceStateConfig | CommonDebugServiceFieldType,
		key: string,
	): ICommonDebugComponentServiceStateConfig {
		let newState: ICommonDebugComponentServiceStateConfig;

		if (isString(state)) {
			newState = {
				type: state,
			};
		} else {
			newState = clone(state);
		}

		if (!newState.name) {
			newState.name = key;
		}

		if (!newState.field) {
			newState.field = newState.name;
		}

		if (!newState.get) {
			newState.get = (component) => {
				return component[newState.field];
			};
		}

		if (!newState.set) {
			newState.set = (component: any, value: any) => {
				return component[newState.field] = value;
			};
		}

		return newState;
	}

	static parseConfig (config: ICommonDebugComponentServiceConfig): ICommonDebugComponentServiceConfig {
		forEach(config.props, (state: ICommonDebugComponentServiceStateConfig, key: string) => {
			config.props[key] = this.parseField(state, key);
		});

		forEach(config.states, (state: ICommonDebugComponentServiceStateConfig | CommonDebugServiceFieldType, key: string) => {
			config.states[key] = this.parseField(state, key);
		});

		if (config.testsFile) {
			const rows = config.testsFile.split('\n');
			const tests: ICommonDebugComponentServiceTest[] = [];
			const describeReg = /^(\s*)describe\(['"]([^'"]+)['"]/;
			const itReg = /^(\s*)it\(['"]([^'"]+)['"]/;

			forEach(rows, (row) => {
				if (describeReg.test(row)) {
					const match = row.match(describeReg);

					tests.push({
						type: 'describe',
						level: match[1].length,
						text: match[2],
					});

					return;
				}

				if (itReg.test(row)) {
					const match = row.match(itReg);

					tests.push({
						type: 'it',
						level: match[1].length,
						text: match[2],
					});

					return;
				}
			});

			config.tests = tests;

		} else {
			config.tests = [];
		}

		return config;
	}

	protected configs: {
		[key: string]: ICommonDebugComponentServiceConfig,
	} = {};

	protected components: {
		[key: string]: {
			[key: string]: any,
		},
	} = {};

	saveConfig (config: ICommonDebugComponentServiceConfig) {
		this.configs[config.name] = CommonDebugComponentService.parseConfig(config);
	}

	getConfig (name: string): ICommonDebugComponentServiceConfig {
		return this.configs[name];
	}

	getApiFields (name: string): ICommonDebugComponentServiceProps {
		return this.configs[name].props;
	}

	getStateFields (name: string): ICommonDebugComponentServiceStates {
		return this.configs[name].states;
	}

	getDefaultProps (name: string): { [key: string]: any } {
		const result = {};

		forEach(this.configs[name].props, (state: ICommonDebugComponentServiceStateConfig, key: string) => {
			result[key] = state.defaultValue;
		});

		return result;
	}

	registerComponent (config: ICommonDebugComponentServiceConfig, id: string, component: any) {
		if (!this.components[config.name]) {
			this.components[config.name] = {};
		}

		this.components[config.name][id] = component;
	}

	getComponent (configName: string, id: string): any {
		return this.components[configName][id];
	}
}

export const commonDebugComponentService = new CommonDebugComponentService();
