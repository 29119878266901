// New version src/Common/pipes/common-number-formatter.pipe.ts
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/common_number_formatter_filter.js
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/common_number_with_units_formatter_filter.js

import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtilitiesCore } from '../utilities/core.service';
import { CommonNAFormatPipe } from './common-na-format.pipe';
import {
	CommonPrettyNumberPipe,
	ICommonPrettyNumberOptions,
} from './common-pretty-number.pipe';


@Pipe({
	name: 'commonNumberFormatter',
})
export class CommonNumberFormatterPipe implements PipeTransform {
	constructor (
		private utilities: CommonUtilitiesCore,
		private naFormatter: CommonNAFormatPipe,
		private prettyNumberFormatter: CommonPrettyNumberPipe,
	) {}

	transform(num: number, unit: string = '', opt?: ICommonPrettyNumberOptions) {
		if (!this.utilities.hasValue(num)) {
			return this.naFormatter.transform(num);
		}

		return this.prettyNumberFormatter.transform(num, opt) + unit;
	}
}
