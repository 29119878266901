import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Directive } from '@angular/core';
import { CommonEmailValidator } from '@CaseOne/Common/control_validators/email/email.validator';
import { CommonBaseService } from '@CaseOne/Common/common-base-service.class/common-base-service.class';

@Directive({
	selector: '[commonEmail]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: CommonEmailValidatorDirective,
		multi: true,
	}],
})
export class CommonEmailValidatorDirective extends CommonBaseService implements Validator {
	private commonEmailValidator = this.injector.get(CommonEmailValidator);

	validate(control: AbstractControl): ValidationErrors | null {
		return this.commonEmailValidator.getValidator()(control);
	}
}
