import {
	Component,
	Injector,
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import {
	FormGroup,
	FormControl,
} from '@angular/forms';
import { UIRouter } from '@uirouter/angular';
import {
	isNil,
	isEmpty,
} from 'lodash';

import { CommonAppDataService } from '../../../Common/app_data/app_data.service';
import { CommonEscapedBracketReplace } from '../../../Common/pipes/escape_bracket_replace.filter';

import { AuthenticationUserService } from '../user/user_authentication.service';
import { AuthenticationErrorService } from '../authentication/services/authentication_error.service';
import { CommonBaseComponent } from '../../../Common/base_component/base.component';

@Component({
	selector: 'authentication-registration-link',
	templateUrl: './registration_link.component.pug',
})
export class AuthenticationRegistrationLinkComponent extends CommonBaseComponent {
	appData = this.commonAppDataService.getData();

	state = {
		isSaving: false,
		isSubmitted: false,
		isError: !this.appData.isRegistrationByLinkEnabled || !!(this.appData.registrationErrorTitle || this.appData.registrationErrorText),
	};

	resource = {
		validationCustomErrors: {},
	};

	user = {
		InviteKey: location.search.replace(/[?&]key=([^&]*)/i, '$1'),
		ConfirmPassword: '',
		Password: '',
		FirstName: '',
		LastName: '',
		Email: '',
	};

	registrationTexts = {
		terms: new CommonEscapedBracketReplace().transform(
			this.instant('authentication.form.user_registration.terms_links.text', {
				appName: this.appData.appName,
				privacyPolicyLink: this.getPrivacyPolicyLink(),
				termsOfServiceLink: this.getTermsOfServiceLink(),
			}),
		),
		errorTitle: this.getErrorTitle(),
		errorText: this.getErrorText(),
	};

	onChangeFieldHandler = this.authenticationErrorService.onChangeFieldHandler;

	constructor (
		private commonAppDataService: CommonAppDataService,
		private authenticationUserService: AuthenticationUserService,
		private authenticationErrorService: AuthenticationErrorService,
		private uIRouter: UIRouter,
		protected injector: Injector,
	) {
		super(injector);
	}

	goToLogin (): void {
		this.uIRouter.stateService.go('login');
	}

	register (form: FormGroup): void {
		if (form.valid) {
			this.user.ConfirmPassword = this.user.Password;
			this.state.isSaving = true;

			this.authenticationUserService
				.register(this.user)
				.pipe(
					finalize(() => this.state.isSaving = false),
				)
				.subscribe((response: any) => {
					if (!isNil(response.Data.Errors) && !isEmpty(response.Data.Errors)) {
						this.authenticationErrorService.handleSuccessResponse(response.Data, this.resource.validationCustomErrors, form.controls.email as FormControl);
					} else {
						this.state.isSubmitted = true;
					}
				});
		}
	}

	private getPrivacyPolicyLink (): string {
		return `<a target="_blank" href="/privacypolicy" class="b-authentication-form-link">${ this.instant('authentication.form.user_registration.terms_links.privacy_policy_in_text') }</a>`;
	}

	private getTermsOfServiceLink (): string {
		return `<a target="_blank" href="/termsofservice" class="b-authentication-form-link">${ this.instant('authentication.form.user_registration.terms_links.terms_of_service_in_text') }</a>`;
	}

	private getErrorText (): string {
		return !this.appData.isRegistrationByLinkEnabled ? this.instant('authentication.form.user_registration.registration_disabled.description') : this.appData.registrationErrorText;
	}

	private getErrorTitle (): string {
		return !this.appData.isRegistrationByLinkEnabled ? this.instant('authentication.form.user_registration.registration_disabled.title') : this.appData.registrationErrorTitle;
	}
}
