import { NgModule } from '@angular/core';

import { CommonLocaleModule } from '../locale/locale.module';
import { CommonPageTitleService } from './page-title.service';

@NgModule({
	providers: [
		CommonPageTitleService,
	],
	imports: [
		CommonLocaleModule,
	],
})
export class CommonPageTitleModule {}
