import { ComponentRef, Inject, Injectable } from '@angular/core';
import { PERFORMANCE, WINDOW } from '@ng-web-apis/common';
import { enableDebugTools } from '@angular/platform-browser';
import { debounce, isNil } from 'lodash';
import { isAppPerformanceCreated } from '@CaseOne/Common/utilities/performance-check-utils/performance-check-utils';


export interface ICommonDebugPerformanceMeasure {
	isMeasured: boolean;

	start: () => void;
	stop: () => ICommonDebugPerformanceMeasureResult;
	debouncedStop: () => ICommonDebugPerformanceMeasureResult;
	clear: () => void;
}

export interface ICommonDebugPerformanceMeasureResult {
	performanceEntry: PerformanceEntry,
	endTime: number,
}

export interface ICommonDebugPerformanceMeasureOptions {
	timeout?: number,
}

@Injectable({
	providedIn: 'root',
})
export class CommonDebugService {
	readonly isDebugToolsEnabled: boolean;
	readonly isPerformanceCheckEnabled: boolean;

	constructor(
		@Inject(PERFORMANCE) private performance: Performance,
		@Inject(WINDOW) private window: Window,
	) {
		this.isDebugToolsEnabled = !!this.window.AppData?.IsDebugToolsEnabled;  // without CommonAppDataService to minimize dependencies on initialization
		this.isPerformanceCheckEnabled = !!this.window.AppData?.IsPerformanceCheckEnabled;
	}

	enableDebugTools(componentRef: ComponentRef<any>) {
		if (this.isDebugToolsEnabled) {
			enableDebugTools(componentRef);
		}
	}

	createPerformanceMeasure = (
		componentName: string,
		markAName: string,
		markBName: string,
		options: ICommonDebugPerformanceMeasureOptions = {}
	): ICommonDebugPerformanceMeasure =>  {
		if (this.isPerformanceCheckEnabled) {
			const measureName = `${componentName} from ${markAName} to ${markBName}`;
			const timeout = isNil(options?.timeout) ? 5000 : options?.timeout;
			let lastPerformanceMarkCreated = false;
			let lastPerformanceNow: number = 0;
			const clear = () => {
				try {
					if (this.isPerformanceCheckEnabled) {
						this.performance.clearMarks(markAName);
						this.performance.clearMarks(markBName);
						this.performance.clearMeasures(measureName);
						lastPerformanceMarkCreated = false;
					}
				} catch (e) {
					console.error(e);
				}
			};
			const start = () => {
				try {
					if (this.isPerformanceCheckEnabled) {
						measure.isMeasured = false;
						clear();
						this.performance.mark(markAName);
					}
				} catch (e) {
					console.error(e);
				}
			};
			const createLastPerformanceMark = () => {
				this.performance.clearMarks(markBName);
				this.performance.mark(markBName);
				lastPerformanceNow = this.performance.now();
				lastPerformanceMarkCreated = true;
			};
			const stop = (): ICommonDebugPerformanceMeasureResult | null => {
				let result: ICommonDebugPerformanceMeasureResult;

				try {
					if (this.isPerformanceCheckEnabled && !measure.isMeasured) {
						let performanceEntries: PerformanceEntry[];

						if (!lastPerformanceMarkCreated) {
							createLastPerformanceMark();
						}

						this.performance.measure(measureName, markAName, markBName);
						performanceEntries = this.performance.getEntriesByName(measureName);
						result = performanceEntries?.length ? {
							performanceEntry: performanceEntries[0],
							endTime: lastPerformanceNow,
						} : null;
						clear();
						measure.isMeasured = true;
					}
				} catch (e) {
					console.error(e);
				}

				this.sendPerformanceEntryToWindow(measureName, result);  // For AQA
				// tslint:disable:no-console
				console.info(`${measureName} duration: ${result?.performanceEntry?.duration}; result:`, result);

				return result;
			};
			const internalDebouncedStop = debounce(stop, timeout);
			const debouncedStop = (): ICommonDebugPerformanceMeasureResult | null => {
				try {
					if (this.isPerformanceCheckEnabled) {
						createLastPerformanceMark();
						return internalDebouncedStop();
					}
				} catch (e) {
					console.error(e);
				}
			};
			const measure: ICommonDebugPerformanceMeasure = {
				isMeasured: false,

				start,
				stop,
				debouncedStop,
				clear,
			};

			start();

			return measure;
		}
	}

	private sendPerformanceEntryToWindow(measureName: string, performanceMeasureResult: ICommonDebugPerformanceMeasureResult | null) {
		if (this.isPerformanceCheckEnabled) {
			isAppPerformanceCreated(this.window);
			this.window.AppPerformance.measures[measureName] = performanceMeasureResult;
		}
	}
}
