import { isEmpty, unset, get } from 'lodash';
import { FormControl } from '@angular/forms';

import { CommonNotificationService } from '../../../../Common/notification/notification.service';
import { ICommonResponseError } from '../../../../Common/interfaces/response';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthenticationErrorService {
	constructor (
		private commonNotificationService: CommonNotificationService,
	) {
	}

	handleSuccessResponse (response: ICommonResponseError, validationsErrorObject, control: FormControl): void {
		if (isEmpty(response.Errors)) {
			return;
		}

		if (response.IsUserError) {
			this.showCustomError(response, validationsErrorObject, control);
		} else {
			this.commonNotificationService.handleResponseError(response);
		}
	}

	showCustomError (response, validationsErrorObject, control: FormControl) {
		validationsErrorObject.custom = get(response, 'Errors[0]') || response.Error;
		control.setErrors({CustomError: true});
	}

	onChangeFieldHandler (control: FormControl, resource) {
		if (resource.validationCustomErrors.custom) {
			control.setErrors(null);
			unset(resource, 'validationCustomErrors.custom');
		}
	}
}
