import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'commonListItemsFilter',
})
export class CommonListItemsFilterPipe implements PipeTransform {
	transform(items: any[], searchFieldName: string, searchFieldValue: string): any[] {
		if (!searchFieldValue || !items || !items.length) {
			return items;
		}

		return items.filter((item) => {
			return item[searchFieldName].toLowerCase().includes(searchFieldValue.toLowerCase());
		});
	}
}
