import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonPatternValidatorDirective} from './pattern.directive';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
	],
	exports: [
		CommonPatternValidatorDirective,
	],
	declarations: [
		CommonPatternValidatorDirective,
	],
	entryComponents: [],
})
export class CommonPatternValidatorModule {}
