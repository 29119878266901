// Old version AutocompleteService src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/services/autocomplete_service_factory.js
// New version CommonBaseListResourceService src/Common/base-list-resource.service/common-base-list-resource.service.ts

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { defaults, filter, isArray, isNil, isObject, isString, map as lodashMap, trim, unset } from 'lodash';
import { map as rxjsMap } from 'rxjs/operators';

import { ICommonResponse } from '../interfaces/response';
import {
	cancelObservableRequestPolyfill,
	IPromiseWithCancel,
} from '../utilities/core.service';
import { COMMON_HTTP_METHOD_TYPE } from '../interfaces/common-http-method-type.enum';
// Default params for request in all services
import { COMMON_LIST_RESOURCE_DEFAULT_REQUEST_PARAMS } from './common-base-list-resource-default-request-params.constant';
import { UUID } from '../interfaces/core';
import { Injectable } from '@angular/core';

export interface ICommonListResourceService<ItemType = any> {
	get: (data?: ICommonListResourceServiceQueryData) => IPromiseWithCancel<ICommonResponse<ItemType[]>>
}

export interface ICommonListResourceServiceQueryData {
	Name?: string,
	Ids?: Array<string | UUID>,
	IgnoreIds?: Array<string | UUID>,
	IgnoreValues?: string[],
	PageSize?: number,
	Page?: number,
	[key: string]: any,
}

export interface ICommonBaseListResourceServiceOptions<QueryDataType> {
	url?: string,
	method?: COMMON_HTTP_METHOD_TYPE,
	headers?: string | {  // values for HttpHeaders
		[name: string]: string | string[];
	},
	cancellable?: boolean,
	defaultRequestParams?: Partial<QueryDataType>,
}

// Base class for creating resource services for item list
@Injectable()
export class CommonBaseListResourceService<
		ItemType = any,
		QueryDataType extends ICommonListResourceServiceQueryData = ICommonListResourceServiceQueryData
	> implements ICommonListResourceService<ItemType> {
	url: string = ''; // Serve api url for request
	method: COMMON_HTTP_METHOD_TYPE = COMMON_HTTP_METHOD_TYPE.POST; // Request http method
	headers?: string | {  // values for HttpHeaders
		[name: string]: string | string[];
	};
	cancellable: boolean = true;  // cancel previous request
	defaultRequestParams: Partial<QueryDataType>; // Default params for request in instance service

	private previousRequest: IPromiseWithCancel<ICommonResponse<ItemType[]>>;

	constructor(
		private httpClient: HttpClient,
	) {}

	setConfig(options: ICommonBaseListResourceServiceOptions<QueryDataType>) {
		this.url = options.url || this.url;
		this.method = options.method || this.method;
		this.headers = options.headers || this.headers;
		this.cancellable = options.cancellable !== undefined ? options.cancellable : this.cancellable;
		this.defaultRequestParams = options.defaultRequestParams || this.defaultRequestParams;
	}

	get(requestParams?: QueryDataType): IPromiseWithCancel<ICommonResponse<ItemType[]>> {
		const passDataToRequestThoughBody = [COMMON_HTTP_METHOD_TYPE.POST, COMMON_HTTP_METHOD_TYPE.PUT].includes(this.method);
		const passDataToRequestThoughParams = [COMMON_HTTP_METHOD_TYPE.GET, COMMON_HTTP_METHOD_TYPE.DELETE].includes(this.method);
		requestParams = defaults(requestParams, this.defaultRequestParams, COMMON_LIST_RESOURCE_DEFAULT_REQUEST_PARAMS);

		this.prepareRequestParams(requestParams);

		if (this.cancellable && this.previousRequest) {
			this.previousRequest.$cancelRequest();
		}

		const request = this.httpClient
			.request<ICommonResponse<ItemType[]>>(
				this.method,
				this.url,
				{
					responseType: 'json',
					headers: this.headers ? new HttpHeaders(this.headers) : null,
					params: passDataToRequestThoughParams
						? new HttpParams({
								fromObject: requestParams,
							})
						: null,
					body: passDataToRequestThoughBody ? requestParams : null,
				},
			)
			.pipe(rxjsMap(
				(response) => {
					if (!isArray(response)) {
						response = response || {} as ICommonResponse<ItemType[]>;
						response.Result = response.Result || [];
						response.Result = lodashMap(response.Result, (item) => {
							if (isObject(item) && !isString((item as any).Name)) {
								(item as any).Name = '';
							}
							// else do nothing

							return item;
						});
					}

					return response;
				},
			))
			.pipe(rxjsMap(
				(response) => this.formatter(response),
			));

		this.previousRequest = cancelObservableRequestPolyfill(request);

		return this.previousRequest;
	}

	// Formatter for response
	formatter(response: any): ICommonResponse<ItemType[]> {
		return response;
	}

	// moved form old logic
	prepareRequestParams(requestParams) {
		if (requestParams._ignoreItemsToIds) {
			const ignoreIds = filter(lodashMap(requestParams._ignoreItemsToIds, requestParams._ignoreItemsToIdsFieldPath || 'Id'), (item) => {
				return !isNil(item);
			});
			unset(requestParams, '_ignoreItemsToIds');
			unset(requestParams, '_ignoreItemsToIdsFieldPath');

			if (isArray(requestParams.IgnoreIds)) {
				requestParams.IgnoreIds = requestParams.IgnoreIds.concat(ignoreIds);
			} else {
				requestParams.IgnoreIds = ignoreIds;
			}
		}
		// else do nothing

		if (requestParams.Name) {
			requestParams.Name = trim(requestParams.Name);
		}
	}
}
