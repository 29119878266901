import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonActionsDropdownComponent } from './common-actions-dropdown.component';
import { CommonActionsDropdownOptionsListComponent } from './components/common-actions-dropdown-options-list/common-actions-dropdown-options-list.component';
import { CommonActionsDropdownOptionComponent } from './components/common-actions-dropdown-option/common-actions-dropdown-option.component';
import { CommonDialogModule } from '../../blocks/dialog/common-dialog.module';

@NgModule({
	imports: [
		CommonModule,
		CommonDialogModule,
	],
	exports: [
		CommonModule,
		CommonActionsDropdownComponent,
	],
	declarations: [
		CommonActionsDropdownComponent,
		CommonActionsDropdownOptionsListComponent,
		CommonActionsDropdownOptionComponent,
	],
	entryComponents: [
		CommonActionsDropdownComponent,
		CommonActionsDropdownOptionsListComponent,
	],
})
export class CommonActionsDropdownModule {}
