// New version src/Common/loader/loader.component.ts CommonLoaderComponent
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/controls/common_controls_loader_module/ctrl_loader_component_service.jsx CtrlLoaderComponent

import { ChangeDetectionStrategy, Component, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { TCallbackFunc } from '../../../interfaces/core';
import { CommonDebugComponent } from '../../../controls/control/debug/debug.decorator';
import { CommonBaseComponent } from '../../../base_component/base.component';
import { COMMON_LOADER_DEFAULT_DELAY_TIMEOUT } from '../../const/loader-default-delay-timeout';

@CommonDebugComponent({
	name: 'common-loader',
	designLink: 'https://app.zeplin.io/project/591460ff7793c56928756817/screen/5a37719d5a61c59c7e44d8b7',
	description: 'Annimated loading image',
	props: {
		isLoading: {
			type: 'boolean',
			description: 'Active or not',
			defaultValue: true,
		},
		hasDelay: {
			type: 'boolean',
			description: 'Delay before showing loader',
			defaultValue: false,
		},
		hasMinDuration: {
			type: 'boolean',
			description: 'Minimum loader display time',
			defaultValue: false,
		},
		label: {
			type: 'string',
			description: 'Loaders text',
			defaultValue: 'Label',
		},
		modifiers: {
			type: 'string',
			description: 'CSS classes for control',
			defaultValue: 'sand_box test',
		},

		ctrlLoaderClasses: {
			type: 'string',
			description: 'CSS classes for loader',
			defaultValue: '',
		},
		className: {
			type: 'string',
			description: 'CSS classes for container',
			defaultValue: '',
		},
		ctrlLoaderTopAligned: {
			type: 'boolean',
			description: 'Made align to top',
			defaultValue: false,
		},
		ctrlLoaderWithOverlay: {
			type: 'boolean',
			description: 'With overlay',
			defaultValue: false,
		},
	},
	states: {
		isShow: {
			type: 'boolean',
			description: 'Is it displayed?',
		},
		delayTimeout: {
			type: 'number',
			description: 'Delay before displaying the loader',
		},
		durationTimeout: {
			type: 'number',
			description: 'Minimum loader display time',
		},
		cancelDelayTimer: {
			type: 'boolean',
			description: 'Is there now waiting for the loader to start',
			get: (component) => !!component.cancelDelayTimer,
			set: () => void(0),
		},
		cancelDurationTimer: {
			type: 'boolean',
			description: 'Is there now waiting for the completion of the minimum display time of the loader',
			get: (component) => !!component.cancelDurationTimer,
			set: () => void(0),
		},
	},
})
@Component({
	selector: 'common-loader',
	templateUrl: './loader.component.pug',
	styleUrls: ['./loader.component.sass'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonLoaderComponent extends CommonBaseComponent {
	@Input() isLoading: boolean = false;
	@Input() hasDelay: boolean = false;
	@Input() hasMinDuration: boolean = false;
	@Input() label: string = '';
	@Input() loaderClasses: string = '';
	@Input() loaderTopAligned: boolean = false;
	@Input() loaderWithOverlay: boolean = false;

	isShow: boolean = false;
	isMinDurationOver: boolean = true;

	protected delayTimeout: number = 0;
	protected durationTimeout: number = 0;
	protected cancelDelayTimer: TCallbackFunc;
	protected cancelDurationTimer: TCallbackFunc;

	ngOnInit(): void {
		super.ngOnInit();

		this.willUnbind(() => this.cancelDelayTimer);
		this.willUnbind(() => this.cancelDurationTimer);
	}

	ngOnChanges(changes: SimpleChanges): void {
		super.ngOnChanges(changes);

		if (changes.hasDelay) {
			if (changes.hasDelay.currentValue) {
				this.delayTimeout = COMMON_LOADER_DEFAULT_DELAY_TIMEOUT;
			} else {
				this.delayTimeout = 0;
			}
		}

		if (changes.hasMinDuration) {
			if (changes.hasMinDuration.currentValue) {
				this.durationTimeout = COMMON_LOADER_DEFAULT_DELAY_TIMEOUT;
			} else {
				this.durationTimeout = 0;
			}
		}

		if (changes.isLoading) {
			if (changes.isLoading.currentValue) {
				if (this.hasDelay || this.hasMinDuration) {
					this.startLoader();
				} else {
					this.isShow = true;
					this.runUpdate();
				}
			} else {
				if (this.hasDelay || this.hasMinDuration) {
					this.stopLoading();
				} else {
					this.isShow = false;
					this.runUpdate();
				}
			}
		}
	}

	protected startLoader(): void {
		this.cancelDelayTimer = this.timeout(
			() => this.showLoader(),
			this.delayTimeout,
			() => this.cancelDelayTimer = null,
		);
	}

	protected showLoader(): void {
		this.isMinDurationOver = false;
		this.isShow = true;
		this.runUpdate();

		this.cancelDurationTimer = this.timeout(
			() => {
				this.isMinDurationOver = true;
				this.runUpdate();
			},
			this.durationTimeout,
			() => this.cancelDurationTimer = null,
		);
	}

	protected stopLoading(): void {
		if (this.cancelDelayTimer) {
			this.cancelDelayTimer();
		}

		this.isShow = false;
		this.runUpdate();
	}
}
