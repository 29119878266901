import { AuthenticationLoginComponent } from '../login/login.component';
import { AuthenticationResetPasswordComponent } from '../reset_password/reset_password.component';
import { AuthenticationRegistrationLinkComponent } from '../registration_link/registration_link.component';
import { Ng2StateDeclaration } from '@uirouter/angular';

export const authenticationRoutes: Ng2StateDeclaration[] = [
	{
		name: 'login',
		url: '/login',
		component: AuthenticationLoginComponent,
	},
	{
		name: 'reset',
		url: '/remind/code/:id',
		component: AuthenticationResetPasswordComponent,
	},
	{
		name: 'registration',
		url: '/registration?key',
		component: AuthenticationRegistrationLinkComponent,
	},
];
