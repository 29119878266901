import { NgModule } from '@angular/core';

import { CommonHotkeyService } from './services/hotkey.service';

@NgModule({
	providers: [
		{
			provide: CommonHotkeyService,
			useFactory: ($injector) => $injector.get('CommonHotkeyService'),
			deps: ['$injector'],
		},
	],
})
export class CommonHotkeyModule {}
