import { APP_INITIALIZER, NgModule } from '@angular/core';
import * as  FontFaceObserver from 'fontfaceobserver';

declare const COMPONENT_SPECS_MODE: boolean;

export function initFonts () {
	return () => {
		if (!COMPONENT_SPECS_MODE) {
			const FONT_LOADING_TIMEOUT = 60000;

			return Promise.all([
				new FontFaceObserver('Roboto', { weight: 400 }).load(null, FONT_LOADING_TIMEOUT),
				new FontFaceObserver('Roboto', { weight: 500 }).load(null, FONT_LOADING_TIMEOUT),
				new FontFaceObserver('Roboto', { weight: 700 }).load(null, FONT_LOADING_TIMEOUT),
			]);
		}
	};
}

@NgModule({
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initFonts,
			multi: true,
			deps: [],
		},
	],
})
export class CommonAppFontsModule {}
