import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { CommonPipesModule } from '../../pipes/pipes.module';
import { CommonLoaderModule } from '../../loader/loader.module';
import { CommonValidationModule } from '../control/validation/validation.module';
import { CommonTextfieldComponent } from './textfield.component';
import { CommonTextfieldInputMaskAliasesService } from './inputmasks_aliases_service';

declare const COMPONENT_SPECS_MODE: boolean;

export function initCommonTextfieldInputMaskAliasesService (
	commonTextfieldInputMaskAliasesService: CommonTextfieldInputMaskAliasesService,
) {
	return () => {
		if (!COMPONENT_SPECS_MODE) {  // until https://github.com/angular/angular/issues/24218 is fixed
			return commonTextfieldInputMaskAliasesService.init();
		}
	};
}

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
		CommonValidationModule,
		CommonPipesModule,
		CommonLoaderModule,
	],
	exports: [
		CommonTextfieldComponent,
		FormsModule,
		CommonValidationModule,
	],
	declarations: [
		CommonTextfieldComponent,
	],
	entryComponents: [
		CommonTextfieldComponent,
	],
	providers: [
		CommonTextfieldInputMaskAliasesService,
		{
			provide: APP_INITIALIZER,
			useFactory: initCommonTextfieldInputMaskAliasesService,
			multi: true,
			deps: [
				CommonTextfieldInputMaskAliasesService,
			],
		},
	],
})
export class CommonTextfieldModule {}

