// New version src/Common/control_validators/data_validation.constant.ts DATA_VALIDATION
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/infinite_scroll_module.js DATA_VALIDATION

export const DATA_VALIDATION = {
	email: /^[^ @,;]+@[^ @,;]+$/,
	imageFormats: [
		'.png',
		'.jpg',
		'.jpeg',
		'.gif',
		'.bmp',
		'.jpe',
	],
	url: /^(https?:\/\/)?([\da-zа-яё\.-]+)\.([a-zа-яё\.]{2,6})([\/\w \.-]*)*\/?$/,
	urlWithProtocol: /^((https?:)?\/\/)(\S)*$/,
	cardExpire: /^(0[1-9]|1[0-2])\/\d{2}$/,
	cardExpireMonth: /^(0[1-9]|1[0-2])$/,
	cardExpireYear: /^(\d{2}|\d{4})$/,
	cardNumber: /^\d+$/,
};

