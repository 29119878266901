export function isPerformanceCheckEnabled (win: Window): boolean {
	return !!win.AppData?.IsPerformanceCheckEnabled;
}

export function isAppPerformanceCreated (win: Window): void {
	win.AppPerformance = window.AppPerformance || {
		measures: {},
		timings: {
			sourcesInitialized: null,
			onBeforeStateLoading: null,
			onSuccessStateLoading: null,
		}
	};
}
