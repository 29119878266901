import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonServerDateService } from './server_date.service';

declare const COMPONENT_SPECS_MODE: boolean;

export function initCommonServerDateService (
	commonServerDateService: CommonServerDateService,
) {
	return () => {
		if (!COMPONENT_SPECS_MODE) {  // until https://github.com/angular/angular/issues/24218 is fixed
			return commonServerDateService.init();
		}
	};
}

@NgModule({
	providers: [
		CommonServerDateService,
		{
			provide: APP_INITIALIZER,
			useFactory: initCommonServerDateService,
			multi: true,
			deps: [
				CommonServerDateService,
			],
		},
	],
})
export class CommonServerDateModule {}
