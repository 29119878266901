import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CommonValidator } from '../common-validator';
import { hasValue } from '../../utilities/core.service';
import { CommonInputMaskService } from '../../inputmask/services/common-inputmask.service';

export const COMMON_MASK_ERROR_KEY = 'CommonMaskValidator';
export interface ICommonMaskValidatorContext {
	mask?: string,
}

@Injectable()
export class CommonMaskValidator extends CommonValidator {
	constructor(private maskService: CommonInputMaskService) {
		super();
	}

	getValidator(context: ICommonMaskValidatorContext = {}) {
		return (control: AbstractControl): ValidationErrors | null => {
			const isHasValue = hasValue(control.value);

			if (isHasValue && context.mask && !this.maskService.isValidMaskValue(control.value, context.mask)) {
				return { [COMMON_MASK_ERROR_KEY]: true };
			} else {
				return null;
			}
		};
	}
}
