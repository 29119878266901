import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Inject,
	OnDestroy,
	ViewEncapsulation,
} from '@angular/core';
import {OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
	Observable,
	timer,
} from 'rxjs';

import { COMMON_POPUP_CLASS_NAMES } from '../../constants/popup-class-names.constant';
import { commonConstants } from '../../../constants/common.constants';

@Component({
	selector: 'common-popup-overlay-background',
	templateUrl: './popup-overlay-background.component.pug',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonPopupOverlayBackgroundComponent implements OnDestroy, AfterViewInit {
	@HostBinding('class') hostClassName = COMMON_POPUP_CLASS_NAMES.OVERLAY_BACKGROUND_HOST;

	private containerEl: HTMLElement;

	constructor(
		private container: OverlayContainer,
		@Inject(DOCUMENT) private document: Document,
	) {
		this.containerEl = this.container.getContainerElement();
		this.document.body.classList.add(COMMON_POPUP_CLASS_NAMES.BODY_HOST);
		this.containerEl.classList.add(COMMON_POPUP_CLASS_NAMES.OVERLAY_HOST);
	}

	ngOnDestroy(): void {
		this.document.body.classList.remove(COMMON_POPUP_CLASS_NAMES.BODY_HOST);
		this.containerEl.classList.remove(COMMON_POPUP_CLASS_NAMES.OVERLAY_HOST);
		/**
		 * Display block change need to reset CSS animation.
		 * Fixes: second time open modal background blink bug. Also in ngAfterViewInit.
		 */
		this.containerEl.style.display = 'none';
	}

	ngAfterViewInit(): void {
		this.containerEl.style.display = 'block';

		// Without zonejs
		setTimeout(() => {
			this.containerEl.classList.add(COMMON_POPUP_CLASS_NAMES.OVERLAY_HOST_SHOW);
		});
	}

	close(): Observable<any> {
		this.containerEl.classList.remove(COMMON_POPUP_CLASS_NAMES.OVERLAY_HOST_SHOW);

		return timer(commonConstants.animationDuration);
	}
}
