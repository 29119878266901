import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'commonSafeResourceUrl',
})
export class CommonSafeResorceUrlPipe implements PipeTransform {

	constructor(
		private sanitized: DomSanitizer,
	) {}

	transform(value: string = ''): SafeUrl {
		return this.sanitized.bypassSecurityTrustResourceUrl(value);
	}

}
