import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isEmpty, every, forEach } from 'lodash';
import { CommonValidator } from '../common-validator';

export const COMMON_REQUIRED_GROUP_ERROR_KEY = 'commonRequiredGroup';
export interface ICommonRequiredGroupValidatorContext {
	group: ICommonRequiredGroupValidatorGroup,
}

export interface ICommonRequiredGroupValidatorGroup {
	controls: AbstractControl[],
	oldIsValidFlag: boolean,
}

@Injectable({
	providedIn: 'root',
})
export class CommonRequiredGroupValidator extends CommonValidator {
	getValidator(context: ICommonRequiredGroupValidatorContext) {
		const updateOtherControls = (group, control, isValid) => {
			if (group.oldIsValidFlag !== isValid) {
				group.oldIsValidFlag = isValid;

				forEach(group.controls, (c: AbstractControl) => {
					if (c !== control) {
						c.updateValueAndValidity({ onlySelf: true, emitEvent: false });
					}
				});
			}
		};

		return (control: AbstractControl): ValidationErrors | null => {
			const group = context.group;
			const isEmptyAll = every(group.controls, (c: AbstractControl) => {
				return isEmpty(c.value);
			});

			if (isEmptyAll) {
				updateOtherControls(group, control, true);
				return null;

			} else {
				const isAllHaveValue = every(group.controls, (c: AbstractControl) => {
					return !isEmpty(c.value);
				});

				updateOtherControls(group, control, isAllHaveValue);

				if (isAllHaveValue) {
					return null;
				} else {
					if (isEmpty(control.value)) {
						return {
							[COMMON_REQUIRED_GROUP_ERROR_KEY]: true,
						};
					} else {
						return null;
					}
				}
			}
		};
	}
}
