import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonBackEndValidatorDirective } from './back-end.directive';
import { CommonModule } from '@angular/common';
import { CommonBackEndValidator } from '@CaseOne/Common/control_validators/back-end/back-end.validator';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
	],
	providers: [
		CommonBackEndValidator,
	],
	exports: [
		CommonBackEndValidatorDirective,
	],
	declarations: [
		CommonBackEndValidatorDirective,
	],
})
export class CommonBackEndValidatorModule {}
