// New version src/Common/pipes/common-na-format.pipe.ts
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/common_na_formatter_filter.ts
//             src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/common_na_to_black_formatter_filter.js
import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtilitiesCore } from '../utilities/core.service';

@Pipe({
	name: 'commonNAFormatter',
})
export class CommonNAFormatPipe implements PipeTransform {
	constructor (
		private utilities: CommonUtilitiesCore,
	) {}

	transform(data, toBlank = false) {
		const ph = toBlank ? '' : '—';
		return !this.utilities.hasValue(data) ? ph : data;
	}
}
