import { ChangeDetectionStrategy, Component, InjectionToken } from '@angular/core';

import { ICommonDialogOptions } from '../common-dialog.component';
import { CommonContextMenuBaseScreenComponent } from '../../../context-menu/context-menu.service/components/common-context-menu-base-screen.component';

export const COMMON_DIALOG_CONTEXT_MENU_SCREEN_INTERNAL_INTERFACE = new InjectionToken<ICommonDialogContextMenuScreenInternalInterface<any>>('COMMON_DIALOG_CONTEXT_MENU_SCREEN_INTERNAL_INTERFACE');

export interface ICommonDialogContextMenuScreenInternalInterface<EntityType = any> {
	entity: EntityType,
	getDialogOptions(): ICommonDialogOptions<EntityType>;
	backToDefault?(): void; // Change screen in ContextMenu on list
	close(): void; // Close ContextMenu
}

@Component({
	selector: 'common-actions-dropdown-dialog',
	templateUrl: './common-dialog-context-menu-screen.component.pug',
	styleUrls: ['./common-dialog-context-menu-screen.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonDialogContextMenuScreenComponent<EntityType, OptionsEntityType = EntityType> extends CommonContextMenuBaseScreenComponent {
	commonDialogOptions: ICommonDialogOptions<OptionsEntityType>;
	internalInterface: ICommonDialogContextMenuScreenInternalInterface;
	entity: EntityType;

	ngOnInit() {
		super.ngOnInit();

		this.internalInterface = this.injector.get(COMMON_DIALOG_CONTEXT_MENU_SCREEN_INTERNAL_INTERFACE, null);
		this.backToDefault = this.internalInterface.backToDefault;
		this.commonDialogOptions = this.internalInterface.getDialogOptions();
		this.entity = this.internalInterface.entity;

		if (this.commonDialogOptions) {
			this.commonDialogOptions.declineFunc = this.commonDialogOptions.declineFunc || this.close;
		}
	}

	backToDefault = () => {
		// not empty
	}

	close = () => {
		this.internalInterface.close();
	}
}
