import { isNull, isArray, some, find, isString, map, isEmpty, extend, compact } from 'lodash';
import * as moment from 'moment/moment';

import { Int, UUID } from '../interfaces/core';
import { CommonLocaleService } from '../locale/locale.service';
import { CommonGeneralSettingsService } from '../general_settings/general_settings.service';
import { ICommonGeneralSettingsNonWorkingDay } from '../general_settings/interfaces/general-settings-non-working-day.interface';
import { combineLatest, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { replaceAt } from '@CaseOne/Common/utilities/string/replace-at';

// Helper for generating Opaque types.
type Opaque<T, K> = T & { __opaque__: K };
export type CommonDateFormatString = Opaque<string, 'CommonDateFormatString'>; // 'YYYY-MM-DD'
type NonWorkingRule = (day: moment.Moment) => boolean;

type MomentPeriod = (
	'year' | 'years' | 'y' |
	'month' | 'months' | 'M' |
	'week' | 'weeks' | 'w' |
	'day' | 'days' | 'd' |
	'hour' | 'hours' | 'h' |
	'minute' | 'minutes' | 'm' |
	'second' | 'seconds' | 's' |
	'millisecond' | 'milliseconds' | 'ms'
);

type MomentPeriodQuarter = 'quarter' | 'quarters' | 'Q';

export interface ICommonWeekDay {
	Id: UUID,
	Name: string,
	Order: Int,
	IndexNumber: Int,
	SysName: string,
}

export const DAYS_SYMBOL = 'dd';
export const MONTHS_SYMBOL = 'mm';
export const YEARS_SYMBOL = 'yyyy';
export const DAYS_IN_WEEK = 7;

const DEFAULT_SETTINGS = {
	firstDay: 1,
};

@Injectable()
export class CommonServerDateService {
	readonly daysSymbol: string = DAYS_SYMBOL;
	readonly monthsSymbol: string = MONTHS_SYMBOL;
	readonly yearsSymbol: string = YEARS_SYMBOL;

	readonly secondsInMinute: number = 60;
	readonly minutesInHour: number = 60;
	readonly hoursInDay: number = 24;
	readonly secondsInDay: number = 24 * 60 * 60;
	readonly millisecInSecond: number = 1000;
	readonly daysInWeek: number = DAYS_IN_WEEK;
	readonly datetimeFormat: string = 'YYYY-MM-DD[T]HH:mm:ss.SSSZ';
	readonly dateFormat: string = 'YYYY-MM-DD';
	workingDays: ICommonWeekDay[] = [];
	nonWorkingDays: ICommonGeneralSettingsNonWorkingDay[] = [];

	// input/display date and time formats
	shortDateFormat: string = 'DD.MM.Y';
	inputDateFormat: string = this.shortDateFormat;
	mediumDateFormat: string = 'D MMM Y';
	longDateFormat: string = 'D MMMM Y';
	displayTimeFormat: string = 'HH:mm';
	maskDelimiter: string = '.';
	shortWithTime: string = `${this.shortDateFormat}, ${this.displayTimeFormat}`;
	mediumWithTime: string = `${this.mediumDateFormat}, ${this.displayTimeFormat}`;
	timeWithMedium: string = `${this.displayTimeFormat}, ${this.mediumDateFormat}`;

	dateTestRegex: RegExp = null;
	inputDateTestRegex: RegExp = null;
	inputDateMask: string = '';
	inputDatePlaceholder: string = '';
	inputTimeMask: string = '';
	inputTimePlaceholder: string = '';

	inputFormatWithFirstZero: boolean = -1 < this.inputDateFormat.indexOf('DD');
	amDesignator: string = '';
	pmDesignator: string = '';
	firstDay: number = null;

	private readonly inputTimeRegex: RegExp = /\d{2}:\d{2}/;
	private readonly isoDateTestRegex: RegExp = /(\d{4})\-(\d{2})\-(\d{2})/;
	private readonly yearMonthDayKey: string = 'yyyymmdd';
	private readonly monthDayYearKey: string = 'mmddyyyy';
	private readonly dayMonthYearKey: string = 'ddmmyyyy';

	private readonly dateTestRegexByYear = {
		[this.yearMonthDayKey]: /(\d{4})[\/\-.](\d{1,2})[\/\-.](\d{1,2})/,
		[this.monthDayYearKey]: /(\d{1,2})[\/\-.](\d{1,2})[\/\-.](\d{4})/,
		[this.dayMonthYearKey]: /(\d{1,2})[\/\-.](\d{1,2})[\/\-.](\d{4})/,
	};

	private readonly inputDateTestRegexByYear = {
		[this.yearMonthDayKey]: /(\d{4})[\/\-.](\d{2})[\/\-.](\d{2})/,
		[this.monthDayYearKey]: /(\d{2})[\/\-.](\d{2})[\/\-.](\d{4})/,
		[this.dayMonthYearKey]: /(\d{2})[\/\-.](\d{2})[\/\-.](\d{4})/,
	};
	// Index: 1 - Day, 2 - Month, 3 - Year
	private readonly regexGroupIndexMap = {
		[this.yearMonthDayKey]: [3, 2, 1],
		[this.monthDayYearKey]: [2, 1, 3],
		[this.dayMonthYearKey]: [1, 2, 3],
	};
	private maskWithoutDelimiters;

	private readonly TIME_KEYS_FOR_DST_FIX = ['days', 'day', 'weeks', 'week', 'months', 'month', 'quarters', 'quarter', 'years', 'year', 'd', 'w', 'M', 'Q', 'y'];
	private workingDayIndexes: Int[];
	private nonWorkingDaysRules: NonWorkingRule[];

	private init$ = new BehaviorSubject<boolean>(false);

	constructor (
		private commonLocaleService: CommonLocaleService,
		private commonGeneralSettingsService: CommonGeneralSettingsService,
	) {}

	init (): Promise<boolean> {
		return new Promise((resolve) => {  // for APP_INITIALIZER
			const res = combineLatest([
				this.commonGeneralSettingsService.wait(),
				this.commonLocaleService.wait(),
			]);

			res.subscribe((result) => {
				if (result.length === compact(result).length) {
					this.workingDays = this.commonGeneralSettingsService.getGeneralSettings('WorkingDays');
					this.nonWorkingDays = this.commonGeneralSettingsService.getGeneralSettings('NonWorkingDays');
					this.extendDateFormatsFromGeneralSettings();

					this.setInputSettings();

					this.workingDayIndexes = map(this.workingDays, (day) => {
						const index = day.IndexNumber + 1;

						if (index === DAYS_IN_WEEK) {
							return 0 as Int;
						} else {
							return index as Int;
						}
					});

					this.nonWorkingDaysRules = this.createNonWorkingRules(this.nonWorkingDays);

					this.init$.next(true);
					resolve(true);
				}
			});
		});
	}

	getMoment (dateString = null, isUTC: boolean = false): moment.Moment {
		const value = dateString === 'current' || isNull(dateString) ? undefined : dateString;
		const format = this.isDateString(dateString) && !this.isISODateTimeString(dateString) ? this.shortDateFormat : null;

		return isUTC ? moment.utc(value, format) : moment(value, format);
	}

	getMomentFromTime (timeString: string, format = this.displayTimeFormat): moment.Moment {
		return moment(timeString, format);
	}

	isInputTimeString (timeString: string): boolean {
		return isString(timeString) && this.inputTimeRegex.test(timeString);
	}

	getTimezoneOffset (): string {
		return moment().format('Z');
	}

	isChangedDates (newValues: moment.Moment | string | [], oldValues: moment.Moment | string | []): boolean {
		let isChanged;

		if (isArray(newValues)) {
			isChanged = some(newValues, (value, index) => {
				return !this.getMoment(value).isSame(this.getMoment(oldValues[index]));
			});
		} else {
			isChanged = !this.getMoment(newValues).isSame(this.getMoment(oldValues));
		}

		return isChanged;
	}

	getLastDaysDate (daysCount: number): moment.Moment {
		return moment().add(-daysCount, 'days');
	}

	isDayOff (date: moment.Moment): boolean {
		if (this.isWorkingDayInWeek(date)) {
			return !!find(this.nonWorkingDaysRules, (rule) => {
				return rule(date);
			});

		} else {
			return true;
		}
	}

	isWorkingDayInWeek (date: moment.Moment): boolean {
		const dayIndex = date.day() as Int;
		return -1 < this.workingDayIndexes.indexOf(dayIndex);
	}

	getDiffAsDays (startDate: moment.Moment, endDate: moment.Moment): number {
		const minutesDiff = (endDate.unix() - startDate.unix()) / this.secondsInMinute;
		const offsetDiff = endDate.utcOffset() - startDate.utcOffset();

		return (minutesDiff + offsetDiff) / (this.minutesInHour * this.hoursInDay);
	}

	add (date: moment.Moment, value: number, period: MomentPeriod | MomentPeriodQuarter): moment.Moment {
		const isFixPeriod = -1 < this.TIME_KEYS_FOR_DST_FIX.indexOf(period);

		let oldHour;
		let newHour;
		let diff;

		if (isFixPeriod) {
			oldHour = date.hours();
			date.add(value, period);
			newHour = date.hours();

			if (oldHour !== newHour) {
				diff = this.getCountDiffHours(newHour, oldHour);

				if (0 < diff) {
					date.add(diff, 'hours');
				}
			}

			return date;
		} else {
			return date.add(value, period);
		}
	}

	isDateString (dateString: string): boolean {
		return isString(dateString) && (this.dateTestRegex.test(dateString) ? this.isValidDateFromString(dateString) : this.isISODateTimeString(dateString));
	}

	isValidDateFromString (dateString: string): boolean {
		if (!isString(dateString)) {
			return false;
		}

		const result = this.dateTestRegex.exec(dateString);

		if (!result) {
			return false;
		}

		const [day, month, year] = this.regexGroupIndexMap[this.maskWithoutDelimiters];
		const dayNumber = Number(result[day]);
		const monthNumber = Number(result[month]);
		const yearNumber = Number(result[year]);

		if (Number.isNaN(dayNumber) || Number.isNaN(monthNumber) || Number.isNaN(yearNumber) || monthNumber > 12) {
			return false;
		}

		const daysInMonth = (new Date(yearNumber, monthNumber, 0)).getDate();

		return dayNumber <= daysInMonth;
	}

	// CASEM-68399 bug with incorrect timezone
	getMomentWithFixDSTShiftFromISOString(dateString: string): moment.Moment {
		if (!this.isISODateTimeString(dateString)) {
			return null;
		}

		const result = this.isoDateTestRegex.exec(dateString);
		const [day, month, year] = [3, 2, 1];
		const dayNumber = Number(result[day]);
		const monthNumber = Number(result[month]);
		const yearNumber = Number(result[year]);

		return this.getMomentWithFixDSTShift(dateString, dayNumber, monthNumber, yearNumber);
	}

	setMomentTimeFromDateString (momentDate: moment.Moment, dateString?: string): moment.Moment {
		let hours = 0;
		let minutes = 0;

		// if already set, save time value
		if (dateString) {
			const mCurrent = this.getMoment(dateString);

			hours = mCurrent.hours() || 0;
			minutes = mCurrent.minutes() || 0;
		}

		momentDate.set({
			hour: hours,
			minute: minutes,
			second: 0,
			millisecond: 0,
		});

		return momentDate;
	}

	// CASEM-68399 bug with incorrect timezone
	getMomentWithFixDSTShiftFromInputString(dateString: string): moment.Moment {
		if (!this.isInputDateString(dateString)) {
			return null;
		}

		const result = this.inputDateTestRegex.exec(dateString);
		const [day, month, year] = this.regexGroupIndexMap[this.maskWithoutDelimiters];
		const dayNumber = Number(result[day]);
		const monthNumber = Number(result[month]);
		const yearNumber = Number(result[year]);

		return this.getMomentWithFixDSTShift(dateString, dayNumber, monthNumber, yearNumber);
	}

	// CASEM-68399 bug with incorrect timezone
	private getMomentWithFixDSTShift(dateString: string, dayNumber: number, monthNumber: number, yearNumber: number): moment.Moment {
		const dateMoment = this.getMoment(dateString);
		const momentDate = dateMoment.date();
		const momentMonth = dateMoment.month();
		const momentYear = dateMoment.year();

		if (dayNumber !== momentDate) {  // DST shift
			const daysDiff = new Date(yearNumber, monthNumber - 1, dayNumber) > new Date(momentYear, momentMonth, momentDate) ? 1 : -1;

			dateMoment.add(daysDiff, 'days');
		}

		return dateMoment;
	}

	isInputDateString (dateString: string): boolean {
		return isString(dateString) && (this.inputDateTestRegex.test(dateString) || this.isISODateTimeString(dateString));
	}

	isISODateTimeString (dateString: string): boolean {
		return isString(dateString) && this.isoDateTestRegex.test(dateString);
	}

	isISODateString (dateString: string): boolean {
		return this.isISODateTimeString(dateString) && dateString.length === 10;
	}

	// convert date (or array of dates) to UTC ISO 8601 formatted string
	toISOString (date: moment.Moment | string, isUTC?: boolean): string {
		return date ? this.getMoment(date, isUTC).clone().toISOString() : '';
	}

	toDateString (date: any, isUTC?: boolean): CommonDateFormatString | string {
		return date ? this.getMoment(date, isUTC).clone().format(this.dateFormat) : '';
	}

	toDisplayDateString (date: moment.Moment | string, isUTC?: boolean): string {
		return date ? this.getMoment(date, isUTC).clone().format(this.shortDateFormat) : '';
	}

	toDisplayTimeString (date: moment.Moment | string): string {
		return date ? this.getMoment(date).clone().format(this.displayTimeFormat) : '';
	}

	wait(): BehaviorSubject<boolean> {
		return this.init$;
	}

	isSameOrBefore(date1: moment.Moment | string, date2: moment.Moment | string): boolean {
		return this.getMoment(date1).clone().isSameOrBefore(date2);
	}

	// convert ISO date string (YYYY-MM-DD) and time (HH:mm:ss.SSS or HH:mm) to ISO datetime (YYYY-MM-DD[T]HH:mm:ss.SSSZ)
	// CASEM-68399 bug with incorrect timezone
	convertISODateToDateTime(date: string, time?: string): string {

		if (!date || !this.isISODateString(date)) {
			return date;
		}

		const emptyTime = '00:00:00.000';
		const formattedTime = replaceAt(emptyTime, 0, time || '');
		const datetime = `${date}T${formattedTime}Z`;

		return datetime;
	}

	getMonths(): string[] {
		return moment.months();
	}

	private setInputSettings (): void {
		const monthsSymbol = this.commonLocaleService.instant('common.symbols.months');
		const yearsSymbol = this.commonLocaleService.instant('common.symbols.years');
		const daysSymbol = this.commonLocaleService.instant('common.symbols.days');
		const minutesSymbol = this.commonLocaleService.instant('common.symbols.minutes');
		const hoursSymbol = this.commonLocaleService.instant('common.symbols.hours');

		const mask = (this.inputDateFormat || '')
			.toLowerCase()
			.replace(/d{1,2}/i, DAYS_SYMBOL)
			.replace(/m{1,2}/i, MONTHS_SYMBOL)
			.replace(/y{1,4}/i, YEARS_SYMBOL);

		const placeholder = mask
			.replace(/d/g, daysSymbol)
			.replace(/m/g, monthsSymbol)
			.replace(/y/g, yearsSymbol);

		this.maskWithoutDelimiters = mask.replace(/\W/g, '');
		this.maskDelimiter = this.inputDateFormat.replace(/\w/g, '')[0];

		this.dateTestRegex = this.dateTestRegexByYear[this.maskWithoutDelimiters];
		this.inputDateTestRegex = this.inputDateTestRegexByYear[this.maskWithoutDelimiters];
		this.inputDateMask = mask;
		this.inputDatePlaceholder = placeholder;
		this.inputTimeMask = 'h:s';
		this.inputTimePlaceholder = this.inputTimeMask.replace(/h/g, hoursSymbol + hoursSymbol).replace(/s/g, minutesSymbol + minutesSymbol);
	}

	private extendDateFormatsFromGeneralSettings (): void {
		const dateFormatSettings = this.commonGeneralSettingsService.getGeneralSettings('DateFormat');

		if (!isEmpty(dateFormatSettings)) {
			const timeFormatSettings = this.commonGeneralSettingsService.getGeneralSettings('TimeFormat');

			this.displayTimeFormat = timeFormatSettings.Format;
			this.shortDateFormat = dateFormatSettings.ShortFormat;
			this.mediumDateFormat = dateFormatSettings.MediumFormat;
			this.longDateFormat = dateFormatSettings.LongFormat;
			this.inputDateFormat = this.shortDateFormat;
			this.maskDelimiter = this.inputDateFormat.replace(/\w/g, '')[0];
			this.shortWithTime = `${this.shortDateFormat}, ${this.displayTimeFormat}`;
			this.mediumWithTime = `${this.mediumDateFormat}, ${this.displayTimeFormat}`;
			this.timeWithMedium = `${this.displayTimeFormat}, ${this.mediumDateFormat}`;
			this.amDesignator = timeFormatSettings.AmDesignator;
			this.pmDesignator = timeFormatSettings.PmDesignator;
			this.firstDay = this.commonGeneralSettingsService.getGeneralSettings('DayOfWeek').IndexNumber + 1;
			this.inputFormatWithFirstZero = -1 < this.inputDateFormat.indexOf('DD');
		} else {
			extend(this, DEFAULT_SETTINGS);
		}
	}

	private afterOrEqual (
		firstYear: number,
		firstMonth: number,
		firstDay: number,
		secondYear: number,
		secondMonth: number,
		secondDay: number,
	): boolean {
		return firstYear < secondYear || (firstYear === secondYear && (
			firstMonth < secondMonth || (firstMonth === secondMonth && (
				firstDay < secondDay || firstDay === secondDay)
			))
		);
	}

	private createNonWorkingRules (nonWorkingDays: ICommonGeneralSettingsNonWorkingDay[]): NonWorkingRule[] {
		return map(nonWorkingDays, (day) => {
			const startDate = this.getMoment(day.StartDate);
			const startYear = startDate.year();
			const startMonth = startDate.month();
			const startDayInMonth = startDate.date();

			if (day.EndDate) {
				const endDate = this.getMoment(day.EndDate);
				const endYear = endDate.year();
				const endMonth = endDate.month();
				const endDayInMonth = endDate.date();

				return (testDate: moment.Moment): boolean => {
					const year = testDate.year();
					const month = testDate.month();
					const dayInMonth = testDate.date();
					const afterStart = this.afterOrEqual(startYear, startMonth, startDayInMonth, year, month, dayInMonth);
					const beforeEnd = this.afterOrEqual(year, month, dayInMonth, endYear, endMonth, endDayInMonth);

					return afterStart && beforeEnd;
				};

			} else {
				return (testDate: moment.Moment): boolean => {
					const year = testDate.year();
					const month = testDate.month();
					const dayInMonth = testDate.date();

					return startYear === year && startMonth === month && startDayInMonth === dayInMonth;
				};
			}
		});
	}

	private getCountDiffHours (newHour: number, oldHour: number): number {
		const newHourInRange = 0 <= newHour && newHour < this.hoursInDay;
		const oldHourInRange = 0 <= oldHour && oldHour < this.hoursInDay;

		let leftShift;
		let rightShift;

		if (newHourInRange && oldHourInRange) {
			leftShift = 0;
			rightShift = 0;

			while (true) {
				if (this.normalizeHour(newHour + leftShift) === oldHour) {
					return leftShift;
				} else {
					leftShift -= 1;
				}
				if (this.normalizeHour(newHour + rightShift) === oldHour) {
					return rightShift;
				} else {
					rightShift += 1;
				}
			}
		} else {
			return 0;
		}
	}

	private normalizeHour (hour: number): number {
		if (this.hoursInDay <= hour) {
			return this.normalizeHour(hour - this.hoursInDay);
		} else if (hour < 0) {
			return this.normalizeHour(hour + this.hoursInDay);
		} else {
			return hour;
		}
	}
}
