/**
 * It is Black Magic
 * Used after ALWAYS operation with IEEE754 numbers
 *
 * 1667.00000000002 -> 1667
 * 110.00000000001 -> 110
 * (1.51 - 0.4 = 1.1099999999999999) -> 1.11
 */
export function fixedIEEE754Format (num) {
	return Math.round(parseFloat((num * 10e8).toFixed(2))) / 10e8;
}
