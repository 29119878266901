import '../../CaseDotStar.ServicePackages.Frontend.Common/scripts/common/vendor/element.prototype.polyfills.js';

import {
	find,
	remove,
	map,
	uniqueId,
	isFunction,
	forEach,
	reduce,
	mergeWith,
	isArray,
	isObject,
	has,
	invoke,
	get,
	includes,
	reverse,
} from 'lodash';

import Dotdotdot from 'dotdotdot-js';

import { commonConstants } from '../constants/common.constants';
import { commonBrowserService } from '../browser/common-browser.service';
import { ICommonResponseError } from '../interfaces/response';
import { CommonBaseService } from '../common-base-service.class/common-base-service.class';
import { Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';


interface INoty {
	id: string
	shown: boolean,
	queued: boolean,
	timer: number,
	isClosing: boolean,
	$element: JQuery<HTMLElement>,
	$body: JQuery<HTMLElement>,
	$buttons: JQuery<HTMLElement>,
	show: () => void,
	close: () => void,
	truncate: () => void,
}

interface ICommonNotyOptionsAnimation {
	open: string,
	close: string,
}

interface ICommonNotyOptionsButton {
	callback?: (noty: INoty) => void,
	className?: string,
	title: string,
	href?: string,
	openInCurrentWindow?: boolean,
	closeAfterClick?: boolean,
}

interface ICommonNotyOptions {
	animation?: ICommonNotyOptionsAnimation,
	buttons?: ICommonNotyOptionsButton[],
	icon?: string,
	layout?: string,
	text?: string,
	timeout?: number,
	type?: string,
	notificationId?: string,
}

export type TCommonNotificationType = 'success' | 'info'| 'error' | 'delete_success' | string;
export type TCommonNotificationIconType = 'alert';

export interface ICommonNotyParams {
	buttons?: ICommonNotyOptionsButton[],
	iconType?: TCommonNotificationIconType,
	notificationId?: string,
}

@Injectable({
	providedIn: 'root',
})
export class CommonNotificationService extends CommonBaseService {
	private	NOTIFICATION_HEIGHT = 208;
	private	NOTIFICATION_TIMEOUT = 10000;

	private	notificationsQueue = {
		_queue: [],
		add: (noty: INoty) => {
			if (!find<INoty>(this.notificationsQueue._queue, noty)) {
				this.notificationsQueue._queue.push(noty);
				noty.queued = true;
			}
			return this.notificationsQueue;
		},
		remove: (noty: INoty) => {
			noty.queued = false;
			remove<INoty>(this.notificationsQueue._queue, noty);
			return this.notificationsQueue;
		},
		next: () => {
			return this.notificationsQueue._queue[0];
		},
	};
	private shownCache: Array<{
		noty: INoty,
		height: number,
	}> = [];

	private animationStartEvents = 'webkitAnimationStart animationstart';
	private $body = $('body');
	private isIE = commonBrowserService.browser.isIE;
	private window = this.injector.get(DOCUMENT).defaultView;

	show (
		title: string,
		body: string | {},
		type: TCommonNotificationType,
		params?: ICommonNotyParams,
	): void {
		const notificationId = params?.notificationId || uniqueId('common_noty_bar_');
		const shownItem = find(this.shownCache, (item) => {
			return item.noty.id === notificationId && !item.noty.isClosing;
		});

		if (shownItem) {
			this.restartClosingTimer(shownItem.noty);
		} else {
			const	innerLayout = this.getMessage(title, body);

			// Without zonejs
			setTimeout(() => {
				this.createNotification({
					text: innerLayout,
					type: invoke(type, 'toLowerCase'),
					icon: params?.iconType ? 'icon_' + params.iconType : '',
					buttons: params?.buttons,
					notificationId,
				});
			}, 0);
		}
	}

	handleResponseError (response: ICommonResponseError): void {
		const errorHeader = (get(response, 'data.ErrorHeader') || get(response, 'error.ErrorHeader')) || this.commonLocaleService.instant('common.noty.error.title');
		const errorTypes = ['General', 'Subscription', 'ConditionFailed'];
		const exceptionDetailUrl = get(response, 'data.ExceptionDetailUrl') || get(response, 'error.ExceptionDetailUrl') || response.ExceptionDetailUrl;
		const notificationParams: ICommonNotyOptions = {};
		let errorText = get(response, 'data.Error') || get(response, 'error.Error') || response.Error || '';
		let errorType = get(response, 'data.ErrorType') || get(response, 'error.ErrorType');

		if (exceptionDetailUrl) {
			errorText += `\n${this.commonLocaleService.instant('common.noty.error.download_log_text')}`;
			notificationParams.buttons = [
				{
					title: this.commonLocaleService.instant('common.noty.error.download_log_btn_title'),
					callback: () => {
						this.window.open(exceptionDetailUrl, '_blank');
					},
				},
			];
		}

		if (!includes(errorTypes, errorType)) {
			errorType = 'error';
		}

		errorType = (errorType || '').toLowerCase();
		this.show(errorHeader, errorText, errorType, notificationParams);
	}

	private createNotificationLayout (noty: INoty, options: ICommonNotyOptions): JQuery<HTMLElement> {
		noty.$buttons.append(this.createNotificationButtons(noty, options.buttons));
		noty.$body.append(options.text);

		return noty.$element
			.addClass([
				'b-noty_bar',
				`b-noty_type__${options.type}`,
				`b-noty_theme__${options.icon}`,
			].join(' '))
			.append([noty.$body, noty.$buttons]);
	}

	private createNotificationButtons (noty: INoty, buttons: ICommonNotyOptionsButton[]): Array<JQuery<HTMLElement>>  {
		return map(buttons, (button) => {
			let commonAttrs;
			let $button: JQuery<HTMLElement>;

			button.className = button.className || '';
			button.title = button.title || '';
			commonAttrs = `id='${uniqueId('common_noty_button_')}' class='${button.className}'`;

			if (button.href) {
				$button = $(`<a href='${button.href}' target='${!button.openInCurrentWindow ? '_blank' : '_self'}' ${commonAttrs}>${button.title}</a>`);
			} else {
				$button = $(`<button type='button' ${commonAttrs}>${button.title}</button>`);
			}

			if (isFunction(button.callback)) {
				// Click with zonejs
				$button.on('click', () => {
					button.callback(noty);
				});
			}

			if (button.closeAfterClick) {
				// Click with zonejs
				$button.on('click', () => {
					noty.close();
				});
			}

			return $button;
		});
	}

	private getMaxVisible (): number {
		return Math.floor(window.innerHeight / this.NOTIFICATION_HEIGHT);
	}

	private getContainer (options: ICommonNotyOptions): JQuery<HTMLElement> {
		let $notificationsContainer = this.$body.find('#common_noty_layout__' + options.layout);

		if ($notificationsContainer.length === 0) {
			$notificationsContainer = $(`<div id='common_noty_layout__${options.layout}'></div>`);
			if (this.isIE) {
				$notificationsContainer.addClass('b-noty_no-animate');
			}
			this.$body.append($notificationsContainer);
		}

		return $notificationsContainer;
	}

	private showNext (): void {
		forEach(this.notificationsQueue._queue, (noty) => {
			invoke(noty, 'show');
		});
	}

	private startClosingTimer (noty: INoty, options?: ICommonNotyOptions): void {
		// Without zonejs
		noty.timer = window.setTimeout(noty.close, options?.timeout || this.NOTIFICATION_TIMEOUT);
	}

	private endClosingTimer (noty: INoty): void {
		if (noty.timer) {
			clearTimeout(noty.timer);
			noty.timer = null;
		}
	}

	private restartClosingTimer (noty: INoty) {
		this.endClosingTimer(noty);
		this.startClosingTimer(noty);
	}

	private onAnimationEnd (callback: () => void): () => void {
		return () => {
			// Without zonejs
			setTimeout(callback, commonConstants.animationDuration);
		};
	}

	private animateAll (options: ICommonNotyOptions): void {
		const $notificationsContainer = this.getContainer(options);

		$notificationsContainer.children().each((elementIndex, element) => {
			const $element = $(element);
			const bottom = reduce(this.shownCache, (result, cacheItem, cacheIndex) => {
				if (cacheIndex > elementIndex) {
					return result + cacheItem.height;
				} else {
					return result;
				}
			}, 0);

			$element.css({
				bottom,
			});
		});
	}

	private showNotification (noty: INoty, options: ICommonNotyOptions): INoty {
		let $notificationsContainer: JQuery<HTMLElement>;

		if (!noty.shown) {
			if (!noty.queued) {
				this.showNext();
				this.notificationsQueue.add(noty);
			}

			$notificationsContainer = this.getContainer(options);

			if ($notificationsContainer.children().length < this.getMaxVisible()) {
				$notificationsContainer.append(this.createNotificationLayout(noty, options));

				this.notificationsQueue.remove(noty);
				noty.truncate();
				this.shownCache.push({
					noty,
					height: noty.$element.outerHeight(true)
				});

				this.animateAll(options);

				noty.shown = true;

				noty.$element
					.addClass(options.animation.open)
					.on('mouseenter', () => {
						this.endClosingTimer(noty);
					})
					.on('mouseleave', () => {
						this.startClosingTimer(noty, options);
					})
					// in IE animationend does not fire sometimes
					// emulated animationend from animationstart to ANIMATE_DURATION
					.on(this.animationStartEvents, this.onAnimationEnd(() => {
						this.startClosingTimer(noty, options);
						noty.$element.removeClass(options.animation.open);
						noty.$element.off(this.animationStartEvents);
					}));
			}
		}

		return noty;
	}

	private closeNotification (noty: INoty, options: ICommonNotyOptions): INoty {
		if (noty.shown) {
			this.endClosingTimer(noty);
			noty.isClosing = true;

			noty.$element
				.removeClass(options.animation.open)
				.addClass(options.animation.close)
				// in IE animationend does not fire sometimes
				// emulated animationend from animationstart to ANIMATE_DURATION
				.on(this.animationStartEvents, this.onAnimationEnd(() => {
					noty.shown = false;
					noty.$element.remove();
					remove(this.shownCache, (item) => {
						return item.noty.id === noty.id && item.noty.isClosing;
					});

					if (this.notificationsQueue._queue.length === 0) {
						this.animateAll(options);
					} else {
						this.showNext();
					}
				}));
		}

		return noty;
	}

	private createNotification (options: ICommonNotyOptions): void {
		const	defaults: ICommonNotyOptions = {
			text: '',
			type: 'success',
			layout: 'bottomLeft',
			icon: '',
			timeout: this.NOTIFICATION_TIMEOUT,
			animation: {
				open: 'slideInUp',
				close: 'slideOutLeftFade',
			},
			buttons: [
				{
					title: this.commonLocaleService.instant('common.dismiss'),
					className: 'b-noty_button_close',
					callback: (button) => {
						button.close();
					},
				},
			],
		};

		options = mergeWith({}, defaults, options, (objValue, srcValue) => {
			if (isArray(objValue) && isArray(srcValue)) {
				return objValue.concat(srcValue);
			}
		});

		if (options.buttons) {
			options.buttons = reverse(options.buttons);  // correct buttons order with float: right
		}

		const noty: INoty = {
			id: options.notificationId,
			shown: false,
			queued: false,
			timer: null,
			isClosing: false,
			$element: $(`<div id='${options.notificationId}'></div>`),
			$body: $('<div class="b-noty_body"></div>'),
			$buttons: $('<div class="b-noty_buttons"></div>'),
			show: () => {
				return this.showNotification(noty, options);
			},
			close: () => {
				return this.closeNotification(noty, options);
			},
			truncate: () => {
				// tslint:disable-next-line:no-unused-expression
				new Dotdotdot(
					document.querySelector('.b-noty-text-body--ellipsis'),
					{
						height: 160, // 10 text lines
					},
				);
			},
		};

		noty.show();
	}

	private getMessage (title: string, body: string | {}): string {
		// CASEM-51657 span Need for IE 11
		let bodyEl = `<div class='b-noty-text-body b-noty-text-body--ellipsis'><span>${body || ''}</span></div>`;

		if (isObject(body) && has(body, 'template')) {
			// now use template as string instead of templateUrl
			// this using in administration
			bodyEl = `<div class='b-noty-text-body'>${get(body, 'template')}</div>`;
		}

		const template = `
			<div class='b-noty-text-container'>
				<div class='b-noty-text-icon'></div>
				<div class='b-noty-text-info'>
						<div class='b-noty-text-title g-textoverflow' title='${title}'>${title}</div>
						${bodyEl}
				</div>
			</div>
		`;

		return template;
	}
}
