// New version src/Common/constants/common.constants.ts commonConstants
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/infinite_scroll_module.js

export const commonConstants = {
	downloadFilesAPIUrl: 'api/download',
	downloadFilesAsPDFUrl: 'api/Download/DownloadAsPdf',
	USER_AVATAR_SIZE: {
		small: 32,
		standart: 96,
		profile: 208,
		/**
		 * Backend return error Access for images with sizes different to:
		 * 32x32, 80x80, 96x96, 208x208, 64x64, 192x192, 416x416
		 * https://pravo.slack.com/archives/G46V27N1F/p1575457185313800?thread_ts=1575453073.309800&cid=G46V27N1F
		 */
	},
	animationDuration: 200,
	popoverOpenDelay: 200,
	popoverCloseDelay: 200,
	scrollDownMinScrollSpace: 480,
	defaultNumberDigitsAfterDecimal: 2,
	defaultNumberDecimalSeparator: '.',
	defaultNumberGroupSeparator: ' ',
	dataValidation: {
		url: /^(https?:\/\/)?([\da-zа-яё\.-]+)\.([a-zа-яё\.]{2,6})([\/\w \.-]*)*\/?$/,
		imageFormats: '.png, .jpg, .jpeg, .gif, .bmp, .jpe',
		inn: /^[\d-\s]+$/,
		intNum: /^[0-9]+$/,
		cardExpire: /^(0[1-9]|1[0-2])\/\d{2}$/,
		cardExpireMonth: /^(0[1-9]|1[0-2])$/,
		cardExpireYear: /^(\d{2}|\d{4})$/,
		cardNumber: /^\d+$/,
		digits: /^\d+$/,
	},
};

