import { Injectable } from '@angular/core';
import { CommonBaseListResourceService } from '../../../base-list-resource.service/common-base-list-resource.service';
import { COMMON_HTTP_METHOD_TYPE } from '../../../interfaces/common-http-method-type.enum';
import { ICommonGeneralSettingsDaysOfWeekModel } from '../interfaces/general-settings-days-of-week-model.interface';
import { ICommonResponse } from '../../../interfaces/response';

@Injectable()
export class CommonGeneralSettingsDaysOfWeekResourceService extends CommonBaseListResourceService<ICommonGeneralSettingsDaysOfWeekModel> {
	url = 'api/daysOfWeek/GetDaysOfWeek';
	method = COMMON_HTTP_METHOD_TYPE.GET;

	// Back-end returns in response only collection (without IsSuccess, Result etc..)
	formatter(response: ICommonGeneralSettingsDaysOfWeekModel[]): ICommonResponse<ICommonGeneralSettingsDaysOfWeekModel[]> {
		return {
			IsSuccess: true,
			Result: response || [],
		} as ICommonResponse<ICommonGeneralSettingsDaysOfWeekModel[]>;
	}
}
