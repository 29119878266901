// New version src/Common/pipes/common-title.filter.ts CommonTitleFilter
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/common-title.filter.ts commonTitleFilter

import { Pipe, PipeTransform } from '@angular/core';

interface ICommonTitleFilterOptions {
	floatLabel?: string,
	placeholder?: string,
	hideValue?: boolean
}

export const COMMON_TITLE_LINE_SEPARATOR = '\n';

@Pipe({
	name: 'commonTitleFilter',
})
export class CommonTitlePipe implements PipeTransform {
	transform (value: string, options: ICommonTitleFilterOptions = {}): string {
		const { floatLabel, placeholder, hideValue } = options;

		if (value) {
			if (hideValue) {
				return floatLabel ? floatLabel : '';
			}

			if (floatLabel) {
				return floatLabel + COMMON_TITLE_LINE_SEPARATOR + value;
			} else {
				return value;
			}
		} else {
			return placeholder || '';
		}
	}
}
