import { Injectable } from '@angular/core';

import {
	assign,
	cloneDeep,
	get,
} from 'lodash';

import { CommonBootstrapService } from '../bootstrap/bootstrap.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CommonGeneralSettingsService {
	private init$ = new BehaviorSubject<boolean>(false);
	private generalSettings = {};

	constructor (private commonBootstrapService: CommonBootstrapService) {}

	init (): Promise<boolean> {
		return new Promise((resolve) => {  // for APP_INITIALIZER
			const bootstrap = this.commonBootstrapService.getBootstrap<any>();

			if (bootstrap) {
				this.setGeneralSettings(bootstrap.GeneralSettings);
			}

			this.init$.next(true);
			resolve(true);
		});
	}

	setGeneralSettings (data: {}): void {
		assign(this.generalSettings, data);
	}

	getGeneralSettings (key?: string) {
		return cloneDeep(key ? get(this.generalSettings, key) : this.generalSettings);
	}

	hasMeridiem (): boolean {
		const timeFormat = this.getGeneralSettings('TimeFormat');

		return timeFormat ? !!(timeFormat.PmDesignator || timeFormat.AmDesignator) : false;
	}

	wait(): BehaviorSubject<boolean> {
		return this.init$;
	}
}
