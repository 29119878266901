/**
 * Took from Angular AbstractControl
 *
 * The validation status of the control. There are four possible
 * validation status values:
 *
 * * **VALID**: This control has passed all validation checks.
 * * **INVALID**: This control has failed at least one validation check.
 * * **PENDING**: This control is in the midst of conducting a validation check.
 * * **DISABLED**: This control is exempt from validation checks.
 *
 * These status values are mutually exclusive, so a control cannot be
 * both valid AND invalid or invalid AND disabled.
 */
export const COMMON_CONTROL_STATUS = {
	VALID: 'VALID',
	INVALID: 'INVALID',
	PENDING: 'PENDING',
	DISABLED: 'DISABLED',
};
