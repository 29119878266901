import { Component, Injector } from '@angular/core';

import { CommonAppDataService } from '../../../Common/app_data/app_data.service';
import { CommonBaseComponent } from '../../../Common/base_component/base.component';

import { AuthenticationErrorService } from './services/authentication_error.service';

@Component({
	selector: 'authentication-app',
	templateUrl: './authentication.component.pug',
	providers: [
		AuthenticationErrorService,
	],
})

export class AuthenticationComponent extends CommonBaseComponent {
	appData: any;

	constructor (
		commonAppDataService: CommonAppDataService,
		protected injector: Injector,
	) {
		super(injector);

		this.appData = commonAppDataService.getData();
	}
}
