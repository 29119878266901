// New version src/Common/directives/common_autofocus/common_autofocus.directive.ts
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/directives/ng_autofocus_directive.js

import {
	AfterViewInit,
	Directive,
	ElementRef,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { isNil } from 'lodash';

@Directive({
	selector: '[commonAutofocus]',
})
export class CommonAutofocusDirective implements AfterViewInit, OnChanges {
	@Input('commonAutofocusElement') elementSelector: string = 'input';
	@Input('commonAutofocusDelay') delay: number = 0;
	@Input('commonAutofocusTrigger') trigger: boolean = true;

	private element: HTMLElement;

	constructor(
		private elementRef: ElementRef,
	) {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			!isNil(changes.trigger) &&
			!changes.trigger.firstChange &&
			changes.trigger.currentValue
		) {
			// timeout 400 = css $transition--duration and delay. Safari fix for https://jira.parcsis.org/browse/CASEM-25883
			this.triggerFocus(400);
		}
	}

	ngAfterViewInit(): void {
		this.element = this.elementSelector ?
			this.elementRef.nativeElement.querySelector(this.elementSelector) :
			this.elementRef.nativeElement;

		this.triggerFocus(this.delay);
	}

	private triggerFocus(delay: number) {
		if (this.trigger) {
			// Without zonejs
			setTimeout(() => {
				this.element.focus();
			}, delay);
		}
	}
}
