import {Injectable} from '@angular/core';
import {hasValue} from '../../utilities/core.service';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {isArray} from 'lodash';
import {CommonValidator} from '../common-validator';

export const COMMON_REQUIRED_ERROR_KEY = 'CommonRequiredValidator';

export interface ICommonRequireValidatorContext {
	enabled?: boolean,
	arrayRequiredEnabled?: boolean,
}

@Injectable({
	providedIn: 'root',
})
export class CommonRequireValidator extends CommonValidator {
	getValidator(context: ICommonRequireValidatorContext = {}) {
		return (control: AbstractControl): ValidationErrors | null => {
			if (context.enabled !== false) {
				const arrayRequiredEnabled = context.arrayRequiredEnabled !== false; // Default true
				let isValid = hasValue(control.value);

				if (arrayRequiredEnabled && isArray(control.value)) {
					isValid = control.value.length > 0;
				}

				if (!isValid) {
					return { [COMMON_REQUIRED_ERROR_KEY]: true };
				} else {
					return null;
				}
			} else {
				return null;
			}
		};
	}
}
