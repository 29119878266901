import {CommonCheckboxComponent} from './checkbox.component';
import {NgModule} from '@angular/core';
import {CommonChooseTrueValidatorModule} from '../../control_validators/choose_true/choose_true.module';
import {CommonValidationModule} from '../control/validation/validation.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CommonCheckboxColorStylePipe } from '@CaseOne/Common/controls/checkbox/checkbox-color-style.pipe';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
		CommonChooseTrueValidatorModule,
		CommonValidationModule,
	],
	exports: [
		CommonCheckboxComponent,

		FormsModule,
		CommonChooseTrueValidatorModule,
		CommonValidationModule,
	],
	declarations: [
		CommonCheckboxComponent,
		CommonCheckboxColorStylePipe,
	],
	entryComponents: [
		CommonCheckboxComponent,
	],
})
export class CommonCheckboxModule {}
