import {
	FormGroup,
	FormControl,
} from '@angular/forms';
import {
	Component,
	Injector,
} from '@angular/core';
import {
	isEmpty,
	merge,
} from 'lodash';
import { finalize } from 'rxjs/operators';
import {
	UIRouter,
	Transition,
} from '@uirouter/angular';

import { CommonAppDataService } from '../../../Common/app_data/app_data.service';
import { CommonBaseComponent } from '../../../Common/base_component/base.component';

import { AuthenticationUserService } from '../user/user_authentication.service';
import { AuthenticationErrorService } from '../authentication/services/authentication_error.service';

@Component({
	selector: 'authentication-reset-password',
	templateUrl: './reset_password.component.pug',
})
export class AuthenticationResetPasswordComponent extends CommonBaseComponent {
	state = {
		isLoading: false,
	};

	resource = {
		validationCustomErrors: {
			passwordsMatch: 'authentication.form.reset.messages.match',
			custom: {},
		},
	};

	passwords = {
		newPassword: '',
		confirmPassword: '',
	};

	onChangeFieldHandler = this.authenticationErrorService.onChangeFieldHandler;

	constructor (
		private authenticationUserService: AuthenticationUserService,
		private authenticationErrorService: AuthenticationErrorService,
		private transition: Transition,
		private uIRouter: UIRouter,
		private commonAppDataService: CommonAppDataService,
		protected injector: Injector,
	) {
		super(injector);
	}

	goToLoginPage (authFlashMessage?: string): void {
		if (authFlashMessage) {
			this.commonAppDataService.setDataByPath(authFlashMessage, 'authFlashMessage');
		}

		this.uIRouter.stateService.go('login');
	}

	reset (form: FormGroup): void {
		if (form.valid) {
			this.state.isLoading = true;

			this.authenticationUserService
				.resetPassword(merge({}, this.passwords, { key: this.transition.params().id }))
				.pipe(
					finalize(() => this.state.isLoading = false),
				)
				.subscribe((response: any) => {
					if (response.Succeeded) {
						this.goToLoginPage(this.instant('authentication.form.reset.messages.success'));
					} else if (!isEmpty(response.Errors)) {
						this.authenticationErrorService.showCustomError(response, this.resource.validationCustomErrors, form.controls.newPassword as FormControl);
					}
				});
		}
	}
}
