import { ICommonUser } from './user_data.service';

export class UserDataUpgradedService {
	setUserDataInfo(value, key?) {
		// Non-empty block
	}

	getUserDataInfo(): ICommonUser;
	getUserDataInfo(key?): any {
		return null;
	}

	getUserAvatarUrl(id, mode?) {
		// Non-empty block
	}

	getUserForSuggest() {
		// Non-empty block
	}
}

export const UserDataUpgradedServiceProvider = {
	provide: UserDataUpgradedService,
	useFactory: ($injector) => $injector.get('UserDataService'),
	deps: ['$injector'],
};
