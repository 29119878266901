import {
	ICommonContextMenuOptionsScreen,
	TCommonContextMenuScreenName,
} from '../context-menu.service/common-context-menu.interfaces';


export enum COMMON_ACTIONS_DROPDOWN_LABEL_TYPE {
	DEFAULT = 'COMMON_ACTIONS_DROPDOWN_LABEL_TYPE.DEFAULT',
	LABEL = 'COMMON_ACTIONS_DROPDOWN_LABEL_TYPE.LABEL',
	LINK = 'COMMON_ACTIONS_DROPDOWN_LABEL_TYPE.LINK',
}

export enum COMMON_ACTIONS_DROPDOWN_CONTENT_TYPE {
	DEFAULT = 'COMMON_ACTIONS_DROPDOWN_CONTENT_TYPE.DEFAULT',
	DIALOG = 'COMMON_ACTIONS_DROPDOWN_CONTENT_TYPE.DIALOG',
	DIALOG_DELETE = 'COMMON_ACTIONS_DROPDOWN_CONTENT_TYPE.DIALOG_DELETE',
}

export enum COMMON_ACTIONS_DROPDOWN_CUSTOM_EVENT_TYPE {
	CURRENT_ACTION_CHANGED = 'COMMON_ACTIONS_DROPDOWN_CUSTOM_EVENT_TYPE.CURRENT_ACTION_CHANGED',
	CURRENT_ACTION_SELECTED_BY_KEYBOARD = 'COMMON_ACTIONS_DROPDOWN_CUSTOM_EVENT_TYPE.CURRENT_ACTION_SELECTED_BY_KEYBOARD',
}

interface IActionScreen extends ICommonContextMenuOptionsScreen {
	name: TCommonContextMenuScreenName; // it is required for actions dropdown for contextMenuOptions.screens generation
}

export type ICommonActionsDropdownComponentActions<EntityType = any> = Array<ICommonActionsDropdownComponentAction<EntityType>>;

export interface ICommonActionsDropdownComponentAction<EntityType = any> {
	id: string, // The identifier for testers is added to the layout, they are also used as screen.name if it is not specified
	name: string | IActionsDropdownComponentActionName<EntityType>, // The text that will be displayed in the title of the menu item
	action?: (entity: EntityType) => void, // Action on clicking on a menu item
	screen?: IActionScreen, // Settings for forming the context menu screen
	data?: ICommonActionsDropdownComponentActionData<EntityType>,
	isEnabled?: (entity: EntityType) => boolean, // Is this menu item available? Default - return true
	labelType?: COMMON_ACTIONS_DROPDOWN_LABEL_TYPE,  // type of item (label, link or default)
	contentType?: COMMON_ACTIONS_DROPDOWN_CONTENT_TYPE, // type of content (default or dialog)
	hasDelimiter?: boolean,  // item has delimeter on the top
	isMultiline?: boolean,  // text-overflow option for label
	iconClasses?: string, // css icon classes for label
}

export interface ICommonActionsDropdownComponentContextMenuData<EntityType> {
	actions: ICommonActionsDropdownComponentActions,
	dropdownComponentApi: {
		isActionEnabled(action: ICommonActionsDropdownComponentAction): boolean;
		onActionClickHandler(action: ICommonActionsDropdownComponentAction): void;
		onActionMouseEnterHandler(action: ICommonActionsDropdownComponentAction): void;
		onActionMouseLeaveHandler(action: ICommonActionsDropdownComponentAction): void;
		getCurrentAction(): ICommonActionsDropdownComponentAction;
	}
}

export type IActionsDropdownComponentActionName<EntityType = any> = (entity: EntityType) => string;

// TODO use ICommonDialogOptions<EntityType> expect 'any' after CommonDialog will be declared in some ngModule
// export interface IActionsDropdownComponentActionData<EntityType = any> extends Partial<ICommonDialogOptions<EntityType>> {
export interface ICommonActionsDropdownComponentActionData<EntityType = any> extends Partial<any> {
	[key: string]: any,
}
