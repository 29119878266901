import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ComponentRef,
	ElementRef,
	EmbeddedViewRef,
	HostBinding,
	NgZone,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import {
	BasePortalOutlet,
	CdkPortalOutlet,
	ComponentPortal,
	TemplatePortal,
} from '@angular/cdk/portal';
import {
	BehaviorSubject,
	Observable,
	timer,
} from 'rxjs';

import { commonConstants } from '../../../constants/common.constants';
import { CommonPopupOptions } from '../../popup-options';
import { COMMON_POPUP_CLASS_NAMES } from '../../constants/popup-class-names.constant';
import { CommonPopupHeaderWrapperComponent } from '../popup-header-wrapper/popup-header-wrapper.component';
import { CommonPopup } from '../../popup';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ICommonActionsDropdownComponentActions } from '../../../context-menu/actions-dropdown.component/common-actions-dropdown.interfaces';
import {
	ICommonContextMenuOptions
} from '@CaseOne/Common/context-menu/context-menu.service/common-context-menu.interfaces';

@Component({
  selector: 'common-popup-container',
  templateUrl: './popup-container.component.pug',
	changeDetection: ChangeDetectionStrategy.Default,
	encapsulation: ViewEncapsulation.None,
})
export class CommonPopupContainerComponent extends BasePortalOutlet implements AfterViewInit {
	@HostBinding('class') hostClassName = COMMON_POPUP_CLASS_NAMES.POPUP_HOST;

	@ViewChild(CdkPortalOutlet, { static: true }) contentPortalOutlet: CdkPortalOutlet;
	@ViewChild(CommonPopupHeaderWrapperComponent, { static: true }) headerWrapper: CommonPopupHeaderWrapperComponent;
	@ViewChild('popupBackground', { static: false }) popupBackground: ElementRef<HTMLElement>;

	public id: string;
	public popupRef: CommonPopup;
	public contextMenuItems: ICommonActionsDropdownComponentActions;
	public popupHeaderStyle$ = new BehaviorSubject<{ [klass: string]: any; }>(null);
	public contextMenuEntity: any;
	public contextMenuOptions: Partial<ICommonContextMenuOptions>;

	isOverlapped: boolean = false;

	private containerEl: HTMLElement;
	private isVisible: boolean = false;
	private destroy: () => void;
	private classNames = new Set()
		.add(COMMON_POPUP_CLASS_NAMES.POPUP_WITH_ANIMATION)
		.add(COMMON_POPUP_CLASS_NAMES.POPUP_SHOW)
		.add(COMMON_POPUP_CLASS_NAMES.POPUP_FADE_OUT);

	constructor(
		public options: CommonPopupOptions,
		public elementRef: ElementRef,
		private container: OverlayContainer,
		private zone: NgZone,
	) {
		super();

		this.elementRef.nativeElement.style.display = 'none';
		this.containerEl = this.container.getContainerElement();
		this.contextMenuItems = this.options.contextMenuItems;
		this.addOverlayClassName();
	}

	attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
		return this.contentPortalOutlet.attachComponentPortal(portal);
	}

	attachHeaderComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
  	return this.headerWrapper.headerWrapperOutlet.attachComponentPortal(portal);
	}

	/**
	 * We dont use templates as content for popup, but need to implement parent abstract method
	 */
	attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
		return;
	}

	setDestroyFunc(
		destroyFunc: () => void,
	) {
  	this.destroy = destroyFunc;
	}

	getPopupClasses(): string {
		const optionsClasses = this.options.popupClasses.split(' ');
		const animationClasses = Array.from(this.classNames);

		return [...optionsClasses, ...animationClasses].join(' ');
	}

	hide(): Observable<any> {
		if (!this.isVisible) {
			return timer();
		} else {
			this.toggleAnimationForOverlappedPopup(false);
			const animationDelay$ = timer(commonConstants.animationDuration);
			this.classNames.add(COMMON_POPUP_CLASS_NAMES.POPUP_FADE_OUT);
			this.isVisible = false;

			animationDelay$
				.subscribe(() => {
					this.elementRef.nativeElement.style.display = 'none';
					this.removeOverlayClassName();
				});

			return animationDelay$;
		}
	}

	show(): Observable<any> {
			/*
				https://jira.parcsis.org/browse/CASEM-60523
				this code should be changed after the integration with the old service
				src\CaseDotStar.ServicePackages.Frontend.Common\scripts\common\services\popup_queue_service_factory.js
				is removed
			*/
		if (this.isVisible) {
			return timer();
		} else {
			const animationDelay$ = timer(commonConstants.animationDuration);
			this.classNames.delete(COMMON_POPUP_CLASS_NAMES.POPUP_WITH_ANIMATION);
			this.elementRef.nativeElement.style.display = 'block';
			this.classNames.delete(COMMON_POPUP_CLASS_NAMES.POPUP_SLIDE_IN);
			this.addOverlayClassName();
			this.isVisible = true;
			/**
			 * 100ms timeout fix for animation bug: no animation on initial display.
			 */
			// Now with zonejs
			setTimeout(() => {
				this.zone.run(() => {
					this.classNames.add(COMMON_POPUP_CLASS_NAMES.POPUP_WITH_ANIMATION);
					this.classNames.add(COMMON_POPUP_CLASS_NAMES.POPUP_SLIDE_IN);
					this.classNames.delete(COMMON_POPUP_CLASS_NAMES.POPUP_FADE_OUT);
				});
			}, 100);


			return animationDelay$;
		}
	}

	clickOnAdditionTitle () {
		this.popupRef.emitClickAdditionTitle({});
	}

	// Click with zonejs
	externalIconClose(): void {
		this.popupRef.close();
	}

	close(): Promise<any> {
		this.destroy();

		return timer().toPromise();
	}

	setContextMenuItems(contextMenuItems: ICommonActionsDropdownComponentActions) {
		this.contextMenuItems = contextMenuItems || [];
	}
	setContextMenuEntity(entity: any) {
		this.contextMenuEntity = entity;
	}
	setContextMenuOptions(options: Partial<ICommonContextMenuOptions>) {
		this.contextMenuOptions = options;
	}

	setPopupHeaderStyle(style: { [klass: string]: any; }) {
		this.popupHeaderStyle$.next(style);
	}

	toggleAnimationForOverlappedPopup (isStartUp: boolean) {
		if (!this.containerEl || !this.isOverlapped) {
			return;
		}

		if (isStartUp) {
			this.popupBackground.nativeElement.classList.add('b-popup-overlap-background--show');
		} else {
			this.popupBackground.nativeElement.classList.add('b-popup-overlap-background--hide');
		}
	}

	ngAfterViewInit () {
		this.toggleAnimationForOverlappedPopup(true);
	}

	private addOverlayClassName() {
		if (this.options.popupOverlayClasses && !this.containerEl.classList.contains(this.options.popupOverlayClasses)) {
			this.containerEl.classList.add(this.options.popupOverlayClasses);
		}
	}

	private removeOverlayClassName() {
		if (this.options.popupOverlayClasses && this.containerEl.classList.contains(this.options.popupOverlayClasses)) {
			this.containerEl.classList.remove(this.options.popupOverlayClasses);
		}
	}
}
