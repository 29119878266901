// New version src/Common/pipes/common-percent-formatter.pipe.ts
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/common_percent_formatter_filter.js

import { Pipe, PipeTransform } from '@angular/core';
import { CommonNumberFormatterPipe } from './common-number-formatter.pipe';

@Pipe({
	name: 'commonPercentFormatter',
})
export class CommonPercentFormatterPipe implements PipeTransform {
	constructor (
		private numberFormatter: CommonNumberFormatterPipe,
	) {}

	transform(num: number) {
		return this.numberFormatter.transform(num, '%');
	}
}
