import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter, HostBinding, Input,
	Output,
} from '@angular/core';

import { CommonFocusedButtonBaseComponent } from '../../../base-focused-button-component/common-focused-button-base.component';

@Component({
	selector: 'common-dialog-button',
	templateUrl: './common-dialog-button.component.pug',
	styleUrls: ['./common-dialog-button.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonDialogButtonComponent extends CommonFocusedButtonBaseComponent {
	@Input() isDisabled: boolean;
	@Output() action = new EventEmitter<Event>();

	@HostBinding('class') get hostClass(): string {
		const classes = [];

		if (this.cssClasses) {
			classes.push(...this.cssClasses.split(' '));
		}
		if (this.isDisabled) {
			classes.push('is-disabled');
		}

		return classes.join(' ');
	}

	onAction($event) {
		if (!this.isDisabled) {
			this.action.emit($event);
		}
	}
}
