import { Injectable, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { COMMON_LOGGER_LOG_LEVEL } from '@CaseOne/Common/logger/logger-log-level.enum';

@Injectable({
	providedIn: 'root',
})
export class CommonLoggerService {
	private window = this.injector.get(DOCUMENT).defaultView;
	private logger = this.window.initialLogger;
	private logEnabled = !!this.logger;

	constructor(
		private injector: Injector,
	) {}

	log (
		message: string,
		level: COMMON_LOGGER_LOG_LEVEL,
		e?: ErrorEvent,
	) {
		if (this.logEnabled) {
			this.logger.log(message, level, e);
		}
	}

	logErrorEvent(e: ErrorEvent) {
		if (this.logEnabled) {
			this.logger.logErrorEvent(e);
		}
	}
}
