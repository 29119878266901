// New version src/Common/utilities/core.service.ts CommonUtilitiesCore
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/services/utilities_service.js Utilities

import { InjectionToken, Injectable } from '@angular/core';
import {
	isNaN,
	forEach,
	extend,
	isNil,
	isEmpty,
	find,
	compact,
	uniqueId,
	get, each,
} from 'lodash';
import {
	Observable,
	Subscription,
	from,
} from 'rxjs';
import {
	tap,
	filter,
} from 'rxjs/operators';
import { ICommonModelWithTempId, UUID } from '@CaseOne/Common/interfaces/core';

export interface ICommonStateDate {
	[key: string]: any,
}

export interface IPromiseWithCancel<T> extends Promise<T> {
	$cancelRequest: () => void,
}

interface ICommonFixedParentIdInRequest {
	ParentId?: UUID,
	ParentTempId?: string,
}

@Injectable()
export class CommonUtilitiesCore {
	cancelRequestPolyfill = cancelRequestPolyfill;
	asyncTimeout = asyncTimeout;
	hasValue = hasValue;
	customConcat = customConcat;
	private textDiv;

	getDataFromCurrentRoute($uiRouter: any): ICommonStateDate {
		const path = $uiRouter.globals.$current.path;
		let data = {};

		forEach(path, (state) => {
			if (state.data) {
				data = extend(data, state.data);
			}
		});

		return data;
	}

	getTextWidth (text, font) {
		let div;

		if (!text) {
			return 0;
		}

		const formattedText = text.replace(/\s/g, '&nbsp;');

		// reuse div object for better performance
		if (this.textDiv) {
			div = this.textDiv;
		} else {
			div = this.textDiv = document.createElement('div');
			div.className = 'g-hidden-measure-text';
			document.body.appendChild(div);
		}

		div.style.font = font;
		div.innerHTML = formattedText;

		return Math.ceil(div.getBoundingClientRect().width);
	}

	rxFinallyOldRequest(promise, callback?: () => void) {
		return from(
			promise.catch((error: any) => {
				if (error && error.status === -1) {
					return error;
				} else {
					throw error;
				}
			}),
		)
			.pipe(
				tap(callback),
				// Remove error from chanel
				filter((error: any) => !error || error.status !== -1),
			);
	}

	closestElem(target: HTMLElement, selector): HTMLElement | null {
		while (target) {
			if (target.matches(selector)) return target;
			else target = target.parentElement;
		}

		return null;
	}

	fixedIEEE754Format(val: number): number {
		return Math.round(parseFloat((val * 10e8).toFixed(2))) / 10e8;
	}
}

export function	customConcat (arr1: any[], arr2: any[], outputOptions) {
	const mergeItems = (array1, array2) => {
		const idIndexMap = {};

		forEach(array1, (item, index) => {
			if (item.Id) {
				idIndexMap[item.Id] = index;
			}
		});

		forEach(array2, (item) => {
			const foundIndex = item.Id ? idIndexMap[item.Id] : null;

			if (!isNil(foundIndex)) {
				array1[foundIndex] = item;
			} else {
				array1.push(item);
			}
		});
	};

	if (!isEmpty(arr1) && !isEmpty(arr2) && outputOptions) {
		const groupByKey = outputOptions.GroupByKey;
		const groupItemsKey = outputOptions.GroupItemsKey;

		forEach(arr2,  (arr2group) => {
			const arr1group = find(arr1, (arr1gr) => {
				return arr1gr[groupByKey].Id === arr2group[groupByKey].Id;
			});

			if (arr1group) {
				mergeItems(arr1group[groupItemsKey], arr2group[groupItemsKey]);
			} else {
				arr1.push(arr2group);
			}
		});
	} else {
		mergeItems(arr1, compact(arr2));
	}

	return arr1;
}

export function ignoreCancellingInPromise<T extends Promise<any>> (promise: T): T {
	return promise
		.catch((error) => {
			if (error?.status === -1) {
				// Ignore
			} else {
				throw error;
			}
		}) as T;
}

export function cancelRequestPolyfill<T> (request: Promise<T> | IPromiseWithCancel<T>): IPromiseWithCancel<T> {
	if ((request as IPromiseWithCancel<T>).$cancelRequest) {
		return request as IPromiseWithCancel<T>;
	}

	let sharedReject;

	const promise = new Promise((resolve, reject) => {
		request.then((result) => resolve(result));
		request.catch((error) => reject(error));

		sharedReject = reject;
	}) as IPromiseWithCancel<T>;

	promise.$cancelRequest = () => {
		sharedReject({
			status: -1,
		});
	};

	promise.catch((error) => {
		/**
		 * Empty catch to avoid console error of
		 * Unhandled Promise Rejection: { status: -1 }
		 * https://jira.parcsis.org/browse/CASEM-58335
		 */
		if (error.status !== -1) {
			throw error;
		}
	});

	return promise;
}

export function cancelObservableRequestPolyfill<T> (request: Observable<T>): IPromiseWithCancel<T> {
	let sharedReject;
	let subscription: Subscription;

	const promise = new Promise((resolve, reject) => {
		subscription = request.subscribe(resolve, reject);
		sharedReject = reject;
	}) as IPromiseWithCancel<T>;

	promise.$cancelRequest = () => {
		subscription.unsubscribe();
		sharedReject({
			status: -1,
		});
	};

	return promise;
}

export function hasValue (value: any): boolean {
	return !isNil(value) && !isNaN(value) && value !== '';
}

export function isNewId (id) {
	return isNil(id) || isTempId(id);
}

export function isTempId (id: string): boolean {
	return /^temp_/.test(id);
}

export function getTempId (): string {
	return uniqueId('temp_') + '_' + Date.now();
}

export function asyncTimeout<T = any> (time: number = 0, result?: T): Promise<T> {
	return new Promise((resolve, reject) => {
		// Without zonejs
		setTimeout(() => {
			resolve(result);
		}, time);
	});
}

// Custom sort method against usual .sort() used because Chrome sometime incorrectly sorts array with objects. https://jira.parcsis.org/browse/CASEM-35978
// https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
export function insertionSortWithIgnoreCase (array: Array<any>, field: string): Array<any> {
	for (let i = 0; i < array.length; i++) {
		const temp = array[i];
		let j = i - 1;

		while (j >= 0 && get(array[j], field) && (get(array[j], field).toLowerCase() > get(temp, field).toLowerCase())) {
			array[j + 1] = array[j];
			j--;
		}
		array[j + 1] = temp;
	}

	return array;
}

export enum SETTLED_PROMISE_STATUSES {
	FULLFILLED = 'fulfilled',
	REJECTED = 'rejected',
}

export type SettledPromiseResult<T> = {
	status: SETTLED_PROMISE_STATUSES;
	value?: T;
	reason?: never;
};

function getSettledPromise<T> (promise: Promise<T>): Promise<SettledPromiseResult<T>> {
	return new Promise((resolve, reject) => {
		promise.then((res: T) => {
			resolve({
				status: SETTLED_PROMISE_STATUSES.FULLFILLED,
				value: res,
			});
		}).catch((e) => {
			reject({
				status: SETTLED_PROMISE_STATUSES.REJECTED,
				reason: e,
			});
		});
	});
}

export function settleAllPromises<T> (promises: Promise<T>[]): Promise<SettledPromiseResult<T>[]> {
	if ('allSettled' in Promise) {
		return (Promise as any).allSettled(promises);
	}
	return Promise.all(promises.map((nextPomise: Promise<T>) => getSettledPromise<T>(nextPomise)));
}

// Replace empty ParentId with ParentTempId (useful for suggest-with-tree with temp data, for example)
export function fixParentIdInRequest<T extends ICommonFixedParentIdInRequest> (params: T): T {
	if (isNewId(params.ParentId)) {
		params.ParentTempId = params.ParentId;
		delete params.ParentId;
	}

	return params;
}

// Replace empty Id with TempId (useful for suggest-with-tree with temp data, for example)
export function recursiveIdFixer<T extends ICommonModelWithTempId> (items: T[], childrenFieldName = 'Childrens'): T[] {
	each(items, (item) => {
		if (item.TempId && !item.Id) {
			item.Id = item.TempId as UUID;
		}

		if (item[childrenFieldName]) {
			recursiveIdFixer(item[childrenFieldName], childrenFieldName);
		}
	});

	return items;
}

/** @deprecated */
export const commonUtilitiesCoreService = new CommonUtilitiesCore();

export const COMMON_UTILITES_CORE = new InjectionToken<CommonUtilitiesCore>(
	'common utilites core',
	{
		providedIn: 'root',
    	factory: () => commonUtilitiesCoreService,
	},
);

export function replaceIndexInArray<T> (array: T[], index: number, item: T): T[] {
	array.splice(index, 1, item);
	return array;
}

export function getQueryStringKeyValue (key: string): string {
	const reg = new RegExp('[?&]' + key + '=([^&#]*)', 'i');
	const str = reg.exec(window.location.href);

	return str ? decodeURIComponent(str[1]) : null;
}
