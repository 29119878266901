import { Injector, Pipe, PipeTransform } from '@angular/core';
import { upperFirst } from 'lodash';

import { CommonUtilitiesCore } from '../utilities/core.service';
import { CommonServerDateService } from '../server_date/server_date.service';
import { CommonNAFormatPipe } from './common-na-format.pipe';

export enum COMMON_DATE_PIPE_FORMAT_TYPE {
	SHORT = 'Short',
	MEDIUM = 'Medium',
	LONG = 'Long',
	TIME = 'Time',
	SHORT_WITH_TIME = 'ShortWithTime',
	MEDIUM_WITH_TIME = 'MediumWithTime',
	TIME_WITH_MEDIUM = 'TimeWithMedium',
}

export interface ICommonDatePipeOptions {
	useCommonNAToBlankFormatter?: boolean;
	formatType: COMMON_DATE_PIPE_FORMAT_TYPE
}

@Pipe({
	name: 'commonDateFormatter',
})
export class CommonDatePipe implements PipeTransform {
	private commonServerDateService: CommonServerDateService = this.injector.get(CommonServerDateService);
	private formats = {
		[COMMON_DATE_PIPE_FORMAT_TYPE.SHORT]: this.commonServerDateService.shortDateFormat,
		[COMMON_DATE_PIPE_FORMAT_TYPE.MEDIUM]: this.commonServerDateService.mediumDateFormat,
		[COMMON_DATE_PIPE_FORMAT_TYPE.LONG]: this.commonServerDateService.longDateFormat,
		[COMMON_DATE_PIPE_FORMAT_TYPE.TIME]: this.commonServerDateService.displayTimeFormat,
		[COMMON_DATE_PIPE_FORMAT_TYPE.SHORT_WITH_TIME]: this.commonServerDateService.shortWithTime,
		[COMMON_DATE_PIPE_FORMAT_TYPE.MEDIUM_WITH_TIME]: this.commonServerDateService.mediumWithTime,
		[COMMON_DATE_PIPE_FORMAT_TYPE.TIME_WITH_MEDIUM]: this.commonServerDateService.timeWithMedium,
	};

	constructor(
		private injector: Injector,
		private utilities: CommonUtilitiesCore,
		private commonNAFormatter: CommonNAFormatPipe,
	) {
	}

	transform(date: string | null, options: ICommonDatePipeOptions = {
		formatType: COMMON_DATE_PIPE_FORMAT_TYPE.MEDIUM,
		useCommonNAToBlankFormatter: false,
	}): string {
		if (!this.utilities.hasValue(date)) {
			return this.commonNAFormatter.transform(date, options.useCommonNAToBlankFormatter);
		}

		return upperFirst(this.commonServerDateService.getMoment(date).format(this.formats[options.formatType]));
	}
}
